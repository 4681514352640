export const AUTH_TOKEN_KEY = 'droppii-token'

export const AUTH_REFRESH_TOKEN_KEY = 'droppii-refresh-token'

export const IMAGE_PREFIX = `${process.env.NEXT_PUBLIC_BASE_URL}/uploader-service/v1/uploader`

export const USER_ORDER_KEY_PREFIX = 'order-'

export const BANNER_AUPLAY_DURATION = 3000

export const MAX_REVIEW_COMMENT_CHARACTER = 255

export const MAX_REVIEW_IMAGES = 6

export const MINIMUM_TOP_UP = 2000000

export const ONEPAY_SUPPORT_PHONE_NUMBER = '1900633927'
export const ONEPAY_SUPPORT_EMAIL = 'support@onepay.vn'

export const PDP_FALLBACK_BANNER =
  'https://cdndev.droppii.vn/droppii-dev-public/mobile/images/pdp-banner.png'

export const DROPPII_APPSTORE_LINK =
  'https://apps.apple.com/vn/app/droppii-biz/id1623128934'

export const DROPPII_PLAYSTORE_LINK =
  'https://play.google.com/store/apps/details?id=com.droppii.business&pli=1'
