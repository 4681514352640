import { ButtonScheme, ButtonVariant } from './types'

export interface GetAppearanceButtonStylesTypes {
  disabled?: boolean
  asLabel?: boolean
  scheme?: ButtonScheme
  variant?: ButtonVariant
}

const styleMapping: Record<
  ButtonVariant,
  Record<ButtonScheme, { static: string; hover: string }>
> = {
  solid: {
    primary: {
      static: 'bg-v2-primary-400 text-white',
      hover: 'hover:bg-v2-primary-600',
    },
    secondary: {
      static: 'bg-v2-secondary-400 text-white',
      hover: 'hover:bg-v2-secondary-600',
    },
    success: {
      static: 'bg-v2-success-400 text-white',
      hover: 'hover:bg-v2-success-600',
    },
    warning: {
      static: 'bg-v2-warning-400 text-white',
      hover: 'hover:bg-v2-warning-600',
    },
    danger: {
      static: 'bg-v2-danger-400 text-white',
      hover: 'hover:bg-v2-danger-600',
    },
    light: {
      static: 'bg-v2-gray-25 text-v2-gray-900',
      hover: 'hover:bg-v2-gray-50',
    },
    white: {
      static: 'bg-white text-v2-primary-400',
      hover: '',
    },
    gray: {
      static: 'bg-v2-gray-900 text-white',
      hover: 'hover:bg-v2-gray-700',
    },
  },
  outline: {
    primary: {
      static: 'text-v2-primary-400 border-v2-primary-400',
      hover: 'hover:text-v2-primary-600 hover:border-v2-primary-600',
    },
    secondary: {
      static: 'text-v2-secondary-400 border-v2-secondary-400',
      hover: 'hover:text-v2-secondary-600 hover:border-v2-secondary-600',
    },
    success: {
      static: 'text-v2-success-400 border-v2-success-400',
      hover: 'hover:text-v2-success-600 hover:border-v2-success-600',
    },
    warning: {
      static: 'text-v2-warning-400 border-v2-warning-400',
      hover: 'hover:text-v2-warning-600 hover:border-v2-warning-600',
    },
    danger: {
      static: 'text-v2-danger-400 border-v2-danger-400',
      hover: 'hover:text-v2-danger-600 hover:border-v2-danger-600',
    },
    light: {
      static: 'text-v2-gray-900 border-v2-gray-200',
      hover: 'hover:text-v2-gray-900 hover:border-v2-gray-900',
    },
    white: {
      static: 'text-white border-white',
      hover: '',
    },
    gray: {
      static: 'border-v2-gray-900 text-white',
      hover: '',
    },
  },
  link: {
    primary: {
      static: 'text-v2-primary-400',
      hover: 'hover:text-v2-primary-600',
    },
    secondary: {
      static: 'text-v2-secondary-400',
      hover: 'hover:text-v2-secondary-600',
    },
    success: {
      static: 'text-v2-success-400',
      hover: 'hover:text-v2-success-600',
    },
    warning: {
      static: 'text-v2-warning-400',
      hover: 'hover:text-v2-warning-600',
    },
    danger: {
      static: 'text-v2-danger-400',
      hover: 'hover:text-v2-danger-600',
    },
    light: {
      static: 'text-v2-gray-900',
      hover: 'hover:text-v2-gray-900',
    },
    white: {
      static: 'text-white',
      hover: '',
    },
    gray: {
      static: 'text-v2-gray-900',
      hover: '',
    },
  },
  transparent: {
    primary: {
      static: 'text-v2-primary-400',
      hover: 'hover:bg-v2-primary-25 hover:text-v2-primary-600',
    },
    secondary: {
      static: 'text-v2-secondary-400',
      hover: 'hover:bg-v2-secondary-25 hover:text-v2-secondary-600',
    },
    success: {
      static: 'text-v2-success-400',
      hover: 'hover:bg-v2-success-25 hover:text-v2-success-600',
    },
    warning: {
      static: 'text-v2-warning-400',
      hover: 'hover:bg-v2-warning-25 hover:text-v2-warning-600',
    },
    danger: {
      static: 'text-v2-danger-400',
      hover: 'hover:bg-v2-danger-25 hover:text-v2-danger-600',
    },
    light: {
      static: 'text-v2-gray-900 border-transparent',
      hover: 'hover:bg-v2-gray-50',
    },
    white: {
      static: 'text-white',
      hover: '',
    },
    gray: {
      static: 'text-v2-gray-900',
      hover: 'hover:bg-v2-gray-25',
    },
  },
}

export function getAppearanceButtonStyles({
  disabled = false,
  asLabel = false,
  scheme = 'primary',
  variant = 'solid',
}: GetAppearanceButtonStylesTypes) {
  const classNames = ['transition', 'duration-500']

  if (disabled) {
    classNames.push('bg-opacity-50 text-opacity-50 border-opacity-50')
  }

  classNames.push('border')

  if (variant !== 'outline') {
    classNames.push('border-transparent')
  }

  if (variant !== 'solid') {
    classNames.push('bg-transparent')
  }

  classNames.push(styleMapping?.[variant]?.[scheme]?.static)

  if (!asLabel && !disabled) {
    classNames.push(styleMapping?.[variant]?.[scheme]?.hover)
  }

  return classNames
}
