import { Button } from 'components/ui/Button'
import { Divider } from 'components/ui/Divider'
import { Modal, ModalContent, ModalTitle } from 'components/ui/Modal'
import { Text } from 'components/ui/Text'
import { ROUTES } from 'constants/routes'
import useFeatureFlag from 'hooks/useFeatureFlag'
import { useTranslations } from 'next-intl'
import Link from 'next/link'

interface WelcomeTrialModalProps {
  isOpen: boolean
  onClose: () => void
}

export function WelcomeTrialModal({ isOpen, onClose }: WelcomeTrialModalProps) {
  const t = useTranslations()
  const usKungfuFlow = useFeatureFlag('course.us_kungfu_flow')

  const pathname = usKungfuFlow?.isEnabled
    ? ROUTES.COURSE
    : ROUTES.UPGRADE_ACCOUNT

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent size="max-w-sm" className="text-center !p-4 w-[311px]">
        <div className="flex justify-center">
          <img
            width="174"
            height="60"
            src="/img/droppii.png"
            className="w-fit h-fit object-contain"
            alt="Droppii"
          />
        </div>
        <ModalTitle className="mt-3">
          <Text v2TextColor="Gray-Dark" fontSize="2xl" className="font-bold">
            {t('droppii_welcome')}
          </Text>
        </ModalTitle>
        <Text
          v2TextColor="Gray-Normal"
          fontSize="md"
          className="font-bold mt-2"
        >
          {t.rich('droppii_business_slogan_without_brand')}
        </Text>
        <Text v2TextColor="Gray-Dark" fontSize="md" className="my-4">
          {t.rich('your_account_is_trial', {
            // eslint-disable-next-line react/no-unstable-nested-components
            b: (children) => <b>{children}</b>,
          })}
        </Text>

        <Divider color="400" />

        <Button
          className="mt-4 mb-3 !text-gray-700 !bg-v2-gray-25"
          onClick={onClose}
          fullWidth
        >
          <Text v2TextColor="Gray-Dark" fontSize="md" className="font-medium">
            {t('later')}
          </Text>
        </Button>
        <Link
          href={{
            pathname,
            query: { sourceFrom: 'welcome' },
          }}
        >
          <Button onClick={onClose} fullWidth>
            <Text v2TextColor="White" fontSize="md" className="font-medium">
              {t('upgrade_now')}
            </Text>
          </Button>
        </Link>
      </ModalContent>
    </Modal>
  )
}
