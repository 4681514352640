import { Client } from 'libs/apis'
import fetcher from 'libs/fetcher'
import {
  BaseResponse,
  GetProductsRequest,
  GetProductSearchActivitiesResponse,
  GetProductsResponse,
  GetSearchSuggestRequest,
  GetSearchSuggestResponseItem,
  GetKeywordsAutocompleteRequest,
  GetKeywordsAutocompleteResponseItem,
  Pageable,
} from 'types/schema'
import qs from 'query-string'

class SearchService extends Client {
  public getKeywords(params: { size: number }) {
    return fetcher<BaseResponse<string[]>>(
      `${this.baseUrl}/search-service/v1/app/user/keywords?${qs.stringify(
        params,
      )}`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public getSuggestKeywords(params: GetSearchSuggestRequest) {
    return fetcher<BaseResponse<GetSearchSuggestResponseItem[]>>(
      `${this.baseUrl}/search-service/v1/app/keyword/suggest`,
      {
        headers: this.privateHeaders,
        method: 'POST',
        body: JSON.stringify(params),
      },
    )
  }

  public getProducts(request: GetProductsRequest) {
    return fetcher<BaseResponse<GetProductsResponse[]>>(
      `${this.baseUrl}/search-service/v2/app/product/search`,
      {
        headers: this.privateHeaders,
        method: 'POST',
        body: JSON.stringify(request),
      },
    )
  }

  public getProductCount(request: GetProductsRequest) {
    return fetcher<BaseResponse<Pageable>>(
      `${this.baseUrl}/search-service/v2/app/product/count`,
      {
        headers: this.privateHeaders,
        method: 'POST',
        body: JSON.stringify(request),
      },
    )
  }

  public getProductSearchActivities(size: number) {
    return fetcher<BaseResponse<GetProductSearchActivitiesResponse>>(
      `${this.baseUrl}/search-service/v1/app/user/activities?size=${size}`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public getKeywordsAutocomplete(request: GetKeywordsAutocompleteRequest) {
    return fetcher<BaseResponse<GetKeywordsAutocompleteResponseItem[]>>(
      `${this.baseUrl}/search-service/v1/app/keyword/search`,
      {
        headers: this.privateHeaders,
        method: 'POST',
        body: JSON.stringify(request),
      },
    )
  }

  public deleteSearchKeyword(keywordId: string) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/search-service/v1/app/keyword/${keywordId}`,
      {
        headers: this.privateHeaders,
        method: 'DELETE',
      },
    )
  }

  public deleteAllSearchKeyword() {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/search-service/v1/app/keyword`,
      {
        headers: this.privateHeaders,
        method: 'DELETE',
      },
    )
  }
}

const searchService = new SearchService()

export { searchService }
