import { createElement, HTMLAttributes } from 'react'
import cx from 'classnames'
import { getLineClamp, Truncate, ColorCode, getTextColor } from 'utils/ui'
import { FontSize, getFontSize } from 'utils/typography'

export interface TextProps extends Omit<HTMLAttributes<HTMLElement>, 'color'> {
  as?: 'p' | 'span' | 'small' | 'b' | 'strong' | 'i' | 'em' | 'h6' | 'div'
  truncate?: Truncate
  textColor?: 'none' | '1100' | '700' | '800' | 'primary'
  fontSize?: FontSize
  v2TextColor?: ColorCode
}

function getColor(color: TextProps['textColor']) {
  switch (color) {
    case '1100':
      return 'text-gray-1100'
    case '700':
      return 'text-gray-700'
    case '800':
      return 'text-gray-800'
    case 'primary':
      return 'text-primary'
    default:
      return ''
  }
}

export const Text: React.FC<TextProps> = (props) => {
  const {
    className,
    textColor = '1100',
    fontSize,
    truncate,
    v2TextColor,
    as = 'p',
    ...rest
  } = props
  const classNames = [
    v2TextColor ? getTextColor(v2TextColor) : getColor(textColor),
    fontSize ? getFontSize(fontSize) : undefined,
  ]
  if (truncate) {
    classNames.push(
      typeof truncate === 'boolean' ? 'truncate' : getLineClamp(truncate),
    )
  }

  return createElement(as, { className: cx(className, classNames), ...rest })
}
