import { useRouter as useNextRouter } from 'next/router'
import { ParsedUrlQuery } from 'querystring'
import { removeEmptyAttributes } from 'utils/object'

export function useRouter() {
  const router = useNextRouter()
  const { query, push, replace, asPath } = router
  const backHref = typeof query.from === 'string' ? query.from : undefined

  const replaceQuery = (newQuery: ParsedUrlQuery) => {
    replace(asPath, { query: removeEmptyAttributes({ ...query, ...newQuery }) })
  }

  const pushWithBackLink = (href: string, extraQuery?: Record<string, any>) => {
    push({ pathname: href, query: { from: asPath, ...extraQuery } })
  }

  return { ...router, backHref, pushWithBackLink, replaceQuery }
}
