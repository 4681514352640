import { createContext } from '@dwarvesf/react-utils'
import { useEffect } from 'react'
import toast, { useToasterStore } from 'react-hot-toast'
import { WithChildren } from 'types/common'
import type { ToastScheme, ToastVariant } from '../Toast.types'

const [Provider, useToastAlertContext] = createContext<{
  variant: ToastVariant
  scheme: ToastScheme
}>()

export const ToastAlertProvider = ({
  children,
  ...props
}: WithChildren<{ scheme: ToastScheme; variant: ToastVariant }>) => {
  const { toasts } = useToasterStore()

  // Dismiss stack message. Keep display newest message.
  useEffect(() => {
    toasts
      .filter((t) => t.visible)
      .filter((_, i) => i > 0)
      .forEach((t) => toast.dismiss(t.id))
  }, [toasts])

  return <Provider value={props}>{children}</Provider>
}

export { useToastAlertContext }
