import { isSSR } from '@dwarvesf/react-utils'
import { AUTH_TOKEN_KEY } from 'constants/common'
import { marketingService } from './marketing'
import { orderService } from './order'
import { identityService } from './identity'
import { productService } from './product'
import { searchService } from './search'
import { promotionService } from './promotion'
import { userService } from './user'
import { uploaderService } from './uploader'
import { notificationService } from './notifier'
import { pdpService } from './pdp'

const list = [
  marketingService,
  orderService,
  identityService,
  productService,
  searchService,
  promotionService,
  userService,
  uploaderService,
  notificationService,
  pdpService,
]

const services = {
  setAuthToken: (token: string) => {
    list.forEach((service) => service.setAuthToken(token))
  },
  clearAuthToken: () => {
    list.forEach((service) => service.clearAuthToken())
  },
}

if (!isSSR()) {
  const token = window.localStorage.getItem(AUTH_TOKEN_KEY)
  if (token) {
    services.setAuthToken(token)
  }

  window.addEventListener('storage', (event) => {
    const { key, newValue } = event
    if (key === AUTH_TOKEN_KEY && newValue) {
      services.setAuthToken(newValue)
    }
  })
}

export {
  marketingService,
  orderService,
  identityService,
  productService,
  searchService,
  promotionService,
  userService,
  uploaderService,
  services,
  notificationService,
  pdpService,
}
