import { KeyedMutator } from 'swr'
import React, { useMemo, useState } from 'react'
import { createContext } from '@dwarvesf/react-utils'
import { WithChildren } from 'types/common'
import { useFetchNotificationCategories } from 'hooks/notification/useFetchNotifyCategory'
import { BaseResponse, NotificationCategoryResponse } from 'types/schema'
import { useAuthContext } from './auth'

export const ALL_NOTIFICATIONS_ID = -1

interface NotificationContextValue {
  unReadNotification: number
  allUnReadNotification: number
  currentCategoryId: number | null
  setCurrentCategoryId: (id: number) => void
  categories?: NotificationCategoryResponse[]
  mutate: KeyedMutator<BaseResponse<NotificationCategoryResponse[]>>
}

const [Provider, useNotificationCategory] =
  createContext<NotificationContextValue>({
    name: 'notification-context',
  })

const NotificationProvider = ({ children }: WithChildren) => {
  const { user } = useAuthContext()
  const { categories, mutate } = useFetchNotificationCategories(!user)

  const [currentCategoryId, setCurrentCategoryId] = useState<number | null>(
    null,
  )

  const unReadNotification = useMemo(() => {
    if (categories.length === 0) return 0

    if (currentCategoryId === ALL_NOTIFICATIONS_ID) {
      return categories.reduce((cur, item) => cur + item.realUnRead, 0)
    }
    const category = categories.find((item) => item.id === currentCategoryId)
    return category?.realUnRead ?? 0
  }, [categories, currentCategoryId])

  // Used for global notification badges
  const allUnReadNotification = useMemo(() => {
    if (categories.length === 0) return 0
    return categories.reduce((cur, item) => cur + item.realUnRead, 0)
  }, [categories])

  return (
    <Provider
      value={{
        categories,
        unReadNotification,
        allUnReadNotification,
        currentCategoryId,
        setCurrentCategoryId,
        mutate,
      }}
    >
      {children}
    </Provider>
  )
}

export { useNotificationCategory, NotificationProvider }
