import { Client } from 'libs/apis'
import fetcher from 'libs/fetcher'
import {
  BaseResponse,
  GetCustomerPurchaseHistoryResponseData,
  AddProductToCartRequest,
  AddProductToCartResponse,
  GetOpenCartResponse,
  GetOrderDetailResponse,
  GetOrderQuickSearchRequest,
  CreateOrderCourse,
  GetOrderSupportTypesResponse,
  CreateOrderSupportRequest,
  CreateOrderSupportResponse,
  CreateQuickOrderRequest,
  CreateQuickOrderResponse,
  AddCartItemRequest,
  UpdateCartItemRequest,
  CalculateOrderPriceRequest,
  PaymentMethod,
  GetOrderDeliveryOptionsResponse,
  CalculateOrderPricesResponse,
  UpdateOrderRequest,
  GetOrderSupportResponse,
  OrderItem,
  GetOrderFacetsData,
  GetCommissionResponse,
  CommissionOrdersData,
  GetRankResponse,
  CreateUSOrderCourse,
  CommissionCoachingBenefitData,
  CommissionTrainingBenefitData,
  CalculatePtiProductValuesRequest,
  CalculatePtiProductValuesResponseData,
  VatCompanyRequest,
  VatPersonalRequest,
  OrderPreConfirmationResponse,
  ExportVatDetailResponse,
  CheckAndCreateKungfuV2Request,
  CheckAndCreateKungfuV2Response,
  GetCancelReasonResponse,
  CancelOrderWithReasonCode,
  CancelOrderWithReasonContent,
  GetOrderPaymentMethodsRequest,
  OrderPaymentResponse,
  OrderPaymentLinkResponse,
} from 'types/schema'
import qs from 'query-string'

class OrderService extends Client {
  public getCustomerPurchasedHistory(params: {
    customerId: string
    page: number
    size: number
    createdUserId: string
  }) {
    return fetcher<BaseResponse<GetCustomerPurchaseHistoryResponseData[]>>(
      `${
        this.baseUrl
      }/order-service/v1/app/orders/customer-purchased-history?${qs.stringify(
        params,
      )}`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public getCustomerOrders(params: {
    search?: string
    status?: string
    createdDate?: string
    customerId?: string
    page: number
    size: number
  }) {
    return fetcher<BaseResponse<OrderItem[]>>(
      `${this.baseUrl}/order-service/v1/app/orders?${qs.stringify(params)}`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public confirmOrder(orderId: string, ignoreWarning?: boolean) {
    const queryParams =
      typeof ignoreWarning === 'boolean' ? qs.stringify({ ignoreWarning }) : ''

    return fetcher<BaseResponse<OrderItem[]>>(
      `${this.baseUrl}/order-service/v1/app/orders/${orderId}/confirmation?${queryParams}`,
      {
        headers: this.privateHeaders,
        method: 'PATCH',
      },
    )
  }

  public preConfirmOrder(orderId: string) {
    return fetcher<BaseResponse<OrderPreConfirmationResponse>>(
      `${this.baseUrl}/order-service/v1/app/orders/${orderId}/pre-confirmation`,
      {
        headers: this.privateHeaders,
        method: 'PATCH',
      },
    )
  }

  public addProductToCart(request: AddProductToCartRequest) {
    return fetcher<BaseResponse<AddProductToCartResponse>>(
      `${this.baseUrl}/order-service/v1/app/carts/my-open-cart/items`,
      {
        headers: this.privateHeaders,
        method: 'POST',
        body: JSON.stringify(request),
      },
    )
  }

  public addMultipleProductToCart(request: AddProductToCartRequest[]) {
    return fetcher<BaseResponse<AddProductToCartResponse>>(
      `${this.baseUrl}/order-service/v1/app/carts/my-open-cart/items/multiple`,
      {
        headers: this.privateHeaders,
        method: 'POST',
        body: JSON.stringify(request),
      },
    )
  }

  // cart
  public getOpenCart() {
    return fetcher<BaseResponse<GetOpenCartResponse>>(
      `${this.baseUrl}/order-service/v1/app/carts/my-open-cart`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public addCartItem(params: AddCartItemRequest) {
    return fetcher<BaseResponse<GetOpenCartResponse>>(
      `${this.baseUrl}/order-service/v1/app/carts/my-open-cart/items`,
      {
        headers: this.privateHeaders,
        method: 'POST',
        body: JSON.stringify(params),
      },
    )
  }

  public updateCartItem(params: UpdateCartItemRequest) {
    return fetcher<BaseResponse<GetOpenCartResponse>>(
      `${this.baseUrl}/order-service/v1/app/carts/my-open-cart/items/${params.id}`,
      {
        headers: this.privateHeaders,
        method: 'PATCH',
        body: JSON.stringify(params),
      },
    )
  }

  public deleteCartItem(cartIds: string[]) {
    return fetcher<BaseResponse<GetOpenCartResponse>>(
      `${this.baseUrl}/order-service/v1/app/carts/my-open-cart/items`,
      {
        headers: this.privateHeaders,
        method: 'DELETE',
        body: JSON.stringify(cartIds),
      },
    )
  }

  // orders
  public getOrderDetail(orderId: string) {
    return fetcher<BaseResponse<GetOrderDetailResponse>>(
      `${this.baseUrl}/order-service/v1/app/orders/${orderId}`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public getOrderSetting(key: string) {
    return fetcher<BaseResponse<string | number>>(
      `${this.baseUrl}/order-service/v1/app/orders/settings?key=${key}`,
      {
        headers: this.privateHeaders,
        method: 'GET',
      },
    )
  }

  // Orders support
  public getOrderSupportTypes() {
    return fetcher<BaseResponse<GetOrderSupportTypesResponse>>(
      `${this.baseUrl}/order-service/v1/app/orders/support-requests/types`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public getOrderQuickSearch(params: GetOrderQuickSearchRequest) {
    return fetcher<BaseResponse<GetOrderDetailResponse>>(
      `${this.baseUrl}/order-service/v1/app/orders/quick-search?${qs.stringify(
        params,
      )}`,
      {
        headers: this.privateHeaders,
        method: 'GET',
      },
    )
  }

  public createOrderCourses(params: CreateOrderCourse | CreateUSOrderCourse) {
    return fetcher<BaseResponse<any>>(
      `${this.baseUrl}/order-service/v1/app/orders`,
      {
        headers: this.privateHeaders,
        method: 'POST',
        body: JSON.stringify(params),
      },
    )
  }

  public getOrderSupport(params: {
    year?: number
    month?: number
    page: number
    size: number
    search?: string
  }) {
    return fetcher<BaseResponse<GetOrderSupportResponse[]>>(
      `${
        this.baseUrl
      }/order-service/v1/app/orders/support-requests?${qs.stringify(params)}`,
      {
        headers: this.privateHeaders,
        method: 'GET',
      },
    )
  }

  public createOrderSupportRequest(request: CreateOrderSupportRequest) {
    return fetcher<BaseResponse<CreateOrderSupportResponse>>(
      `${this.baseUrl}/order-service/v1/app/orders/support-requests`,
      {
        headers: this.privateHeaders,
        method: 'POST',
        body: JSON.stringify(request),
      },
    )
  }

  public createQuickOrder(request: CreateQuickOrderRequest) {
    return fetcher<BaseResponse<CreateQuickOrderResponse>>(
      `${this.baseUrl}/order-service/v1/app/orders`,
      {
        headers: this.privateHeaders,
        method: 'POST',
        body: JSON.stringify(request),
      },
    )
  }

  public updateOrder(request: UpdateOrderRequest) {
    return fetcher<BaseResponse<CreateQuickOrderResponse>>(
      `${this.baseUrl}/order-service/v1/app/orders`,
      {
        headers: this.privateHeaders,
        method: 'PUT',
        body: JSON.stringify(request),
      },
    )
  }

  public calculateOrderPrice(request: CalculateOrderPriceRequest) {
    return fetcher<BaseResponse<CalculateOrderPricesResponse>>(
      `${this.baseUrl}/order-service/v1/app/orders/pricing`,
      {
        headers: this.privateHeaders,
        method: 'POST',
        body: JSON.stringify(request),
      },
    )
  }

  public getOrderPaymentMethods(request: GetOrderPaymentMethodsRequest) {
    return fetcher<BaseResponse<PaymentMethod[]>>(
      `${
        this.baseUrl
      }/order-service/v1/app/carts/my-open-cart/payment-options?${qs.stringify(
        request,
      )}`,
      {
        headers: this.privateHeaders,
        method: 'GET',
      },
    )
  }

  public getOrderDeliveryOptions(addressId: string, inventoryId: string) {
    return fetcher<BaseResponse<GetOrderDeliveryOptionsResponse>>(
      `${this.baseUrl}/order-service/v1/app/orders/delivery-options?addressId=${addressId}&inventoryId=${inventoryId}`,
      {
        headers: this.privateHeaders,
        method: 'GET',
      },
    )
  }

  public getOrders(params: {
    search?: string
    status?: string
    createdDate?: string
    page: number
    size: number
  }) {
    return fetcher<BaseResponse<OrderItem[]>>(
      `${this.baseUrl}/order-service/v1/app/orders?${qs.stringify({
        ...params,
        sortedField: 'modifiedDate',
        sortedDirection: 'desc',
      })}`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public cancelOrder(
    params: CancelOrderWithReasonCode | CancelOrderWithReasonContent,
  ) {
    const { orderId, ...body } = params
    return fetcher<BaseResponse<any>>(
      `${this.baseUrl}/order-service/v1/app/orders/${orderId}/canceled`,
      {
        headers: this.privateHeaders,
        method: 'PATCH',
        body: JSON.stringify(body),
      },
    )
  }

  public deleteOrder(params: { orderId: string; modifiedUserId?: string }) {
    const { orderId, ...body } = params
    return fetcher<BaseResponse<any>>(
      `${this.baseUrl}/order-service/v1/app/orders/${orderId}`,
      {
        headers: this.privateHeaders,
        method: 'DELETE',
        body: JSON.stringify(body),
      },
    )
  }

  public getOrderStatusFacets(params: {
    status?: string
    customerId?: string
    createdDate?: string
  }) {
    if (!params.status) {
      params.status = 'ALL'
    }

    return fetcher<BaseResponse<GetOrderFacetsData>>(
      `${this.baseUrl}/order-service/v1/app/orders/status-facet?${qs.stringify(
        params,
      )}`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public getCommissionDetail(params: { month?: number; year?: number }) {
    return fetcher<BaseResponse<GetCommissionResponse>>(
      `${this.baseUrl}/order-service/v1/app/user-incomes/me?${qs.stringify(
        params,
      )}`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public getCommissionOrders(params: {
    year?: number
    month?: number
    searchKey?: string
    page: number
    size: number
  }) {
    return fetcher<BaseResponse<CommissionOrdersData[]>>(
      `${this.baseUrl}/order-service/v1/app/user-incomes/orders?${qs.stringify(
        params,
      )}`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public getCommissionCoachingBenefit(params: {
    year?: number
    month?: number
    searchText?: string
    page: number
    size: number
  }) {
    return fetcher<BaseResponse<CommissionCoachingBenefitData[]>>(
      `${
        this.baseUrl
      }/order-service/v1/app/user-incomes/marketing-commissions/children?${qs.stringify(
        params,
      )}`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public getCommissionTrainingBenefit(params: {
    year?: number
    month?: number
    searchText?: string
  }) {
    return fetcher<BaseResponse<CommissionTrainingBenefitData[]>>(
      `${
        this.baseUrl
      }/order-service/v1/app/user-incomes/consulting-commissions/children?${qs.stringify(
        params,
      )}`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public getRevenueRanking() {
    return fetcher<BaseResponse<GetRankResponse>>(
      `${this.baseUrl}/order-service/v1/app/orders/revenue-ranking`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public calculatePtiProductValues(params: CalculatePtiProductValuesRequest[]) {
    return fetcher<BaseResponse<CalculatePtiProductValuesResponseData[]>>(
      `${this.baseUrl}/order-service/v1/app/orders/dynamic-price-product-calculation`,
      {
        headers: this.privateHeaders,
        method: 'POST',
        body: JSON.stringify(params),
      },
    )
  }

  public createRequestExportVat(
    params: VatCompanyRequest | VatPersonalRequest,
  ) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/order-service/v1/app/orders/vat`,
      {
        headers: this.privateHeaders,
        method: 'POST',
        body: JSON.stringify(params),
      },
    )
  }

  public getVatRequestByOrder(orderId: string) {
    return fetcher<BaseResponse<ExportVatDetailResponse>>(
      `${this.baseUrl}/order-service/v1/app/orders/${orderId}/vat`,
      {
        headers: this.privateHeaders,
        method: 'GET',
      },
    )
  }
  public getVatRequestDetail(vatId: string) {
    return fetcher<BaseResponse<ExportVatDetailResponse>>(
      `${this.baseUrl}/order-service/v1/app/orders/vat/${vatId}`,
      {
        headers: this.privateHeaders,
        method: 'GET',
      },
    )
  }

  public editRequestExportVat({
    vatId,
    params,
  }: {
    vatId: string
    params: VatCompanyRequest | VatPersonalRequest
  }) {
    return fetcher<BaseResponse<ExportVatDetailResponse>>(
      `${this.baseUrl}/order-service/v1/app/orders/vat/${vatId}`,
      {
        headers: this.privateHeaders,
        method: 'PUT',
        body: JSON.stringify(params),
      },
    )
  }

  public changeProductWarehouse({
    fromWarehouseId,
    toWarehouseId,
    cartItemId,
  }: {
    fromWarehouseId: string
    toWarehouseId: string
    cartItemId: string
  }) {
    return fetcher<BaseResponse<GetOpenCartResponse>>(
      `${
        this.baseUrl
      }/order-service/v1/app/carts/my-open-cart/move-warehouse?${qs.stringify({
        fromWarehouseId,
        toWarehouseId,
        cartItemId,
      })}`,
      {
        headers: this.privateHeaders,
        method: 'GET',
      },
    )
  }

  public filterOutUnavailableProductsInCart(warehouseId: string) {
    return fetcher<BaseResponse<GetOpenCartResponse>>(
      `${
        this.baseUrl
      }/order-service/v1/app/carts/my-open-cart/filter-out-unavailable-products?${qs.stringify(
        {
          warehouseId,
        },
      )}`,
      {
        headers: this.privateHeaders,
        method: 'GET',
      },
    )
  }

  public checkAndCreateKungfuV2(params: CheckAndCreateKungfuV2Request) {
    return fetcher<BaseResponse<CheckAndCreateKungfuV2Response>>(
      `${
        this.baseUrl
      }/order-service/v1/app/orders/kungfu_v2/check_kungfu_phone?${qs.stringify(
        params,
      )}`,
      {
        headers: this.privateHeaders,
        method: 'GET',
      },
    )
  }

  public getCancelReason() {
    return fetcher<BaseResponse<GetCancelReasonResponse[]>>(
      `${this.baseUrl}/order-service/v1/app/orders/order-cancel-reason`,
      {
        headers: this.privateHeaders,
        method: 'GET',
      },
    )
  }

  public getOrderPaymentInfo(orderId: string) {
    return fetcher<BaseResponse<OrderPaymentResponse>>(
      `${this.baseUrl}/order-service/v1/public/orders/${orderId}`,
      {
        headers: this.headers,
        method: 'GET',
      },
    )
  }

  public getPublicOrderPaymentLink(orderId: string) {
    return fetcher<BaseResponse<OrderPaymentLinkResponse>>(
      `${this.baseUrl}/order-service/v1/public/orders/${orderId}/payment-link`,
      {
        headers: this.headers,
        method: 'GET',
      },
    )
  }

  public getOrderPaymentLink(orderId: string) {
    return fetcher<BaseResponse<OrderPaymentLinkResponse>>(
      `${this.baseUrl}/order-service/v1/app/orders/${orderId}/payment-link`,
      {
        headers: this.privateHeaders,
        method: 'GET',
      },
    )
  }
}

const orderService = new OrderService()

export { orderService }
