import dayjs from 'dayjs'
import { IS_US_APP } from 'constants/dev'

type DatetimeFormat =
  | 'DD/MM/YYYY - hh:mm A'
  | 'DD/MM/YYYY - HH:mm'
  | 'DD/MM/YYYY HH:mm'
  | 'DD/MM/YYYY'
  | 'HH:mm - DD/MM/YYYY'
  | 'HH:mm A - DD/MM/YYYY'
  | 'HH:mm DD-MM-YYYY'
  | 'HH:mm DD/MM/YYYY'
  | 'HH:mm:ss DD/M/YYYY'
  | 'HH:mm:ss DD/MM/YYYY'
  | 'YYYY-MM-DD'
  | 'MM/DD/YYYY'
  | 'HH:mma - DD/M/YYYY'
  | 'h:mm A - DD/MM/YYYY'
  | 'HH:mm'
  | 'HH:mma - DD/M/YYYY'
  | 'MM/DD/YYYY'

export function formatDatetime(
  date: string,
  format: DatetimeFormat = 'DD/MM/YYYY',
) {
  let customFormat: string = format
  if (IS_US_APP) {
    customFormat = customFormat
      .replace('DD/MM/YYYY', 'MM/DD/YYYY')
      .replace('DD-MM-YYYY', 'MM-DD-YYYY')
  }
  return dayjs(date).format(customFormat)
}

interface Time {
  year: number | string
  month: number | string
}

export function isCurrentMonth(time: Time) {
  const currentYear = dayjs().format('YYYY')
  const currentMonth = dayjs().format('MM')

  return (
    currentYear === time.year.toString() &&
    currentMonth === time.month.toString()
  )
}

export function diffTime(unix: number, unit: 'seconds' | 'days') {
  const now = dayjs()
  const exp = dayjs.unix(unix)
  return exp.diff(now, unit)
}
