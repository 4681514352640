import { ProductTabTypes } from 'components/product/ProductListView'
import { UseTranslationsReturn } from 'types/common'
import {
  BannerType,
  ExportVatStatusType,
  PdpLabelType,
  ProductStatusTypes,
  ProductTypes,
  PromotionCategory,
  PromotionGiftRule,
  PromotionType,
} from 'types/schema'
import { TransactionStatus } from 'utils/marketing'
import { PaymentMethod } from '../types/app'

export const REMAIN_DAY_PROMOTION = 3
export const REMAIN_RESEND_CODE_TIMES = 2
export const MAX_CAROUSE_IMAGES = 10
export const ABLE_RESEND_OTP_SECOND = 60
export const ABLE_RESEND_CONTRACT_OTP_SECOND = 600
export const SUPPORT_PHONE_NUMBER = 19003126

export const PRODUCT_TAB_ENUM: Record<string, ProductTabTypes> = {
  ALL: 1,
  SELLING_PRODUCT: 3,
  PROMOTION_PRODUCT: 4,
  FAVOURITE_PRODUCT: 5,
  NEWEST_PRODUCT: 6,
}
export const PRODUCT_TAB_STRING_ENUM = {
  ALL: 'ALL',
  SELLING_PRODUCT: 'SELLING_PRODUCT',
  PROMOTION_PRODUCT: 'PROMOTION_PRODUCT',
  FAVOURITE_PRODUCT: 'FAVOURITE_PRODUCT',
  NEWEST_PRODUCT: 'NEWEST_PRODUCT',
}

export const REACTION = {
  LIKE: 1,
}
export const PRODUCT_TYPE_ENUM: Record<string, ProductTypes> = {
  NORMAL_PRODUCT: 0,
  PROMOTION_PRODUCT: 1,
  COMBO_PRODUCT: 2,
  SERVICE_PRODUCT: 3,
  CARD_PRODUCT: 4,
  PTI_PRODUCT: 5,
}

export const PRODUCT_STATUS_TYPE_ENUM: Record<string, ProductStatusTypes> = {
  ACTIVE: 1,
  INACTIVE: 2,
  CANCELED: 3,
}

export const ACCOUNT_TYPE = {
  COLLABORATOR: 0,
  NEW_AGENT: 1,
  STANDARD_AGENT: 2,
  STAFF: 3,
  TRIAL: 4,
}

export const ACCOUNT_TYPE_TO_ROLE = {
  0: 'Collaborator',
  1: 'NewAgent',
  2: 'StandardAgent',
  3: 'Staff',
  4: 'Trial',
} as const

export const getAccoutTypeLabel = (
  accountType: string,
  t: UseTranslationsReturn,
) => {
  switch (accountType) {
    case 'Trial':
      return t('role_trial')
    case 'Collaborator':
      return t('role_collaborator')
    case 'NewAgent':
      return t('role_new_agent')
    case 'StandardAgent':
      return t('role_standard_agent')
    default:
      return ''
  }
}

export const PAYMENT_METHOD = {
  BANK: 0,
  COD: 1,
}

export const POPUP_VIEWED = {
  COLLABORATOR: 1,
  AGENT: 2,
}

export const PROMOTION_CATEGORY: Record<string, PromotionCategory> = {
  HOT_DEAL: 'HOT_DEAL',
  SALE_POLICY: 'SALE_POLICY',
}

export const BANNER_TYPE: Record<string, BannerType> = {
  PRODUCT_BANNER: 0,
  PROMOTION_BANNER: 1,
}

export const PROMOTION_TYPES: Record<string, PromotionType> = {
  PRODUCT_GIFT: 'PRODUCT_GIFT',
  COMBO_FIX_DISCOUNT: 'COMBO_FIX_DISCOUNT',
  COMBO_PERCENTAGE_DISCOUNT: 'COMBO_PERCENTAGE_DISCOUNT',
  COMBO_GIFT: 'COMBO_GIFT',
  ORDER_GIFT: 'ORDER_GIFT',
  ON_DEMAND_GIFT: 'ON_DEMAND_GIFT',
}

export const PRMOTION_GIFT_RESULT_TYPES: Record<string, PromotionGiftRule> = {
  RANDOM: 'RANDOM',
  ALL: 'ALL',
}

export const KYC_STATUS = {
  INITIAL: 0,
  PENDING: 1,
  ACCEPTED: 2,
  REJECTED: 3,
}

export const E_CONTRACT_STATUS = {
  INITIAL: 0,
  WAITING_FOR_CONFIRM: 1,
  SIGNED: 2,
  CANCELED: 3,
  CREATOR_SIGNED: 4,
  RECEIVER_SIGNED: 5,
  CREATOR_CANCELED: 6,
  RECEIVER_CANCELED: 7,
}

export const SEX = {
  FEMALE: 1,
  MALE: 0,
}

export const SEX_OPTIONS = [
  {
    value: '0',
    label: 'Nam',
  },
  {
    value: '1',
    label: 'Nữ',
  },
]

export const DATA_LIST_LEVEL = {
  // data is using
  LEVEL0: 0,
  // data expire 60 days
  LEVEL1: 1,
  // data expire > 60 days
  LEVEL2: 2,
  // data is returned
  LEVEL3: 3,
}

export const POLICY_TYPE = ['marketing', 'term']
export const MARKETING_LATEST_VERSION = 1
export const TERM_LATEST_VERSION = 1

export const USER_GAPO_TYPE = {
  NORMAL: 0,
  PREMIUM: 1,
} as const

export const STRIPE_PAYMENT_SUCCESS_STATUS = 3

export type SupportRequestStatus = 'NEW' | 'PROCESSING' | 'COMPLETED'

export const INSTALL_ZALO_LINK = 'https://zalo.me/2394770832615389457?src=qr'

export const INSTALL_TELEGRAM_LINK = 'https://t.me/Droppii_Cambodia_Hotline'

export const DROPPII_INSTALL_APP = 'https://business.droppii.com/app-install'

export const PDP_LABEL: Record<PdpLabelType, string> = {
  DIAMOND: 'Diamond',
  PLATINUM: 'Platinum',
  GOLD: 'Gold',
}

type LabelVatStatusType =
  | 'order_vat_status_waiting'
  | 'order_vat_status_processing'
  | 'order_vat_status_completed'
  | 'order_vat_status_rejected'

type DescriptionVatStatusType =
  | 'order_vat_description_waiting'
  | 'order_vat_description_processing'
  | 'order_vat_description_completed'
  | 'order_vat_description_rejected'

export const REQUEST_VAT_STATUS: Record<
  ExportVatStatusType,
  {
    key: ExportVatStatusType
    labelKey: LabelVatStatusType
    descriptionKey: DescriptionVatStatusType
  }
> = {
  WAITING_FOR_CONFIRM: {
    key: 'WAITING_FOR_CONFIRM',
    labelKey: 'order_vat_status_waiting',
    descriptionKey: 'order_vat_description_waiting',
  },
  PROCESSING: {
    key: 'PROCESSING',
    labelKey: 'order_vat_status_processing',
    descriptionKey: 'order_vat_description_processing',
  },
  COMPLETED: {
    key: 'COMPLETED',
    labelKey: 'order_vat_status_completed',
    descriptionKey: 'order_vat_description_completed',
  },
  REJECTED: {
    key: 'REJECTED',
    labelKey: 'order_vat_status_rejected',
    descriptionKey: 'order_vat_description_rejected',
  },
}

export const PROMOTION_TAG = {
  WARMUP_COMBO: 'WARMUP_COMBO',
}

export const LEAD_STATUS_ENUM = {
  UNREGISTERED: 0,
  ACTIVE: 1,
  INACTIVE: 2,
}

export const TRANSACTION_STATUS_ENUM: Record<
  TransactionStatus | 'ALL',
  number
> = {
  ALL: 0,
  WAITING: 1,
  CONFIRM: 3,
  CANCEL: 4,
}

export type OrderPaymentStatus = 'SUCCESS' | 'FAILED' | 'PROCESSING'

export const PAYMENT_METHOD_ENUM: Record<PaymentMethod, number> = {
  COD: 1,
  BANK_TRANSFER: 2,
  STRIPE: 3,
  ODOS: 4,
  MONE: 5,
  CREDIT_CARD: 6,
  INSTALLMENT_CREDIT: 7,
}

export const ONEPAY_RESPONSE_STATUS_CODE = {
  SUCCESS: '0',
  PROCESSING: '300',
}

export const CONTRACT_STATUS_TYPE_ENUM = {
  PENDING_SIGN: 'PENDING_SIGN',
  ACTIVE: 'ACTIVE',
  EXPIRED: 'EXPIRED',
  PROCESSING: 'PROCESSING',
} as const

export const CONTRACT_TYPE_ENUM = {
  NEW: 'NEW',
  DROPPII_UPDATED: 'DROPPII_UPDATE',
  USER_UPDATED: 'USER_UPDATE',
} as const

export const DIAL_CODES_V2 = [
  {
    url: 'https://cdn.droppii.com/droppii-production-public/assets/united_states_83b69c707d.png',
    dialCode: '1',
    countryCode: 'US',
    countryName: 'United States',
  },
  {
    url: 'https://cdn.droppii.com/droppii-production-public/assets/vietnam_fb8d508a95.png',
    dialCode: '84',
    countryCode: 'VN',
    countryName: 'Vietnam',
  },
  {
    url: 'https://cdn.droppii.com/droppii-production-public/assets/cambodia_4e6076ee12.png',
    dialCode: '855',
    countryCode: 'KH',
    countryName: 'Cambodia',
  },
]
