import { Client } from 'libs/apis'
import qs from 'query-string'
import fetcher from 'libs/fetcher'
import {
  BaseResponse,
  GetComboProductStockQuantityRequest,
  GetComboProductStockQuantityResponse,
  GetProductMetaDataResponse,
  ProductDetailResponse,
  FullyProductDetailResponse,
  GetArticleDetailResponse,
  WarehouseInfo,
  Warehouse,
  CartProductDetailResponse,
  GetNormalProductStockQuantityRequest,
  GetNormalProductStockQuantityResponse,
  GetCartAvailabilityRequest,
  GetCartAvailabilityResponse,
  BannerType,
  GetBannerResponse,
  ProductReview,
  ReviewProductRequest,
  OrderReviewsResponse,
  UpdateReviewRequest,
  ReviewDetail,
  GetProductWarehousesRequest,
  ProductWarehouse,
  GetProductIndustriesResponse,
  GetProductByIdResponse,
  PostDetailData,
} from 'types/schema'

class ProductService extends Client {
  public getComboProductStockQuantity(
    request: GetComboProductStockQuantityRequest,
  ) {
    return fetcher<BaseResponse<GetComboProductStockQuantityResponse[]>>(
      `${this.baseUrl}/product-service/v1/app/products/combo-sku-availability`,
      {
        headers: this.privateHeaders,
        method: 'POST',
        body: JSON.stringify(request),
      },
    )
  }

  public changeProductToFavourite(productId: string) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/product-service/v1/app/products/${productId}/favorite`,
      {
        headers: this.privateHeaders,
        method: 'PUT',
      },
    )
  }

  public getProductMetaData() {
    return fetcher<BaseResponse<GetProductMetaDataResponse>>(
      `${this.baseUrl}/product-service/v1/app/products/metadatas`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public getProductDetail(productId: string) {
    return fetcher<BaseResponse<ProductDetailResponse>>(
      `${this.baseUrl}/product-service/v1/app/products/${productId}/skus`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public getQuicklyProductDetail(productId: string, productType: number) {
    return fetcher<BaseResponse<Partial<FullyProductDetailResponse>>>(
      `${this.baseUrl}/product-service/v1/app/products/${productId}/type/${productType}/cart-quickly/detail`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public getProductById(productId: string) {
    return fetcher<BaseResponse<GetProductByIdResponse>>(
      `${this.baseUrl}/product-service/v1/app/products/${productId}`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public getFullyProductDetail(productId: string, productType: number) {
    return fetcher<BaseResponse<FullyProductDetailResponse>>(
      `${this.baseUrl}/product-service/v1/app/products/${productId}/type/${productType}/cart/detail`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public getNormalProductStockQuantity(
    request: GetNormalProductStockQuantityRequest,
  ) {
    return fetcher<BaseResponse<GetNormalProductStockQuantityResponse[]>>(
      `${this.baseUrl}/product-service/v1/app/products/availability`,
      {
        headers: this.privateHeaders,
        method: 'POST',
        body: JSON.stringify(request),
      },
    )
  }

  public getArticleDetail(productId: string, articleId: string) {
    return fetcher<BaseResponse<GetArticleDetailResponse>>(
      `${this.baseUrl}/product-service/v1/app/products/${productId}/article/${articleId}`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public getCartAvailability(params: GetCartAvailabilityRequest) {
    return fetcher<BaseResponse<GetCartAvailabilityResponse[]>>(
      `${this.baseUrl}/product-service/v1/app/products/cart/availability`,
      {
        headers: this.privateHeaders,
        method: 'POST',
        body: JSON.stringify(params),
      },
    )
  }

  public getInternalWarehouses() {
    return fetcher<BaseResponse<WarehouseInfo[]>>(
      `${this.baseUrl}/product-service/v1/app/warehouses`,
      {
        headers: this.privateHeaders,
        method: 'GET',
      },
    )
  }

  public getBanner(type: BannerType) {
    return fetcher<BaseResponse<GetBannerResponse[]>>(
      `${this.baseUrl}/product-service/v1/app/products/home-banner?type=${type}`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public getProductDetails(productIds: string[]) {
    return fetcher<BaseResponse<CartProductDetailResponse[]>>(
      `${
        this.baseUrl
      }/product-service/v1/app/products/cart/products?${qs.stringify({
        ProductIds: productIds,
      })}`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public getCartWarehouses(warehouseIds: string[]) {
    return fetcher<BaseResponse<Pick<Warehouse, 'id' | 'code' | 'name'>[]>>(
      `${
        this.baseUrl
      }/product-service/v1/app/warehouses/warehouse-id-list/warehouses?${qs.stringify(
        { WarehouseIds: warehouseIds },
      )}`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public getProductReviews({
    productId,
    ratingStar,
    isOnlyMedia,
  }: {
    productId: string
    ratingStar?: number
    isOnlyMedia?: boolean
  }) {
    const params: Record<string, any> = {}
    if (ratingStar) {
      params.ratingStar = ratingStar
    }
    if (isOnlyMedia) {
      params.isOnlyMedia = isOnlyMedia
    }

    return fetcher<BaseResponse<ProductReview>>(
      `${
        this.baseUrl
      }/product-service/v2/app/products/${productId}/reviews?${qs.stringify(
        params,
        { skipNull: true },
      )}`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public getReviewDetail(reviewId: string) {
    return fetcher<BaseResponse<ReviewDetail>>(
      `${this.baseUrl}/product-service/v2/app/products/review/${reviewId}`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public reviewProduct(params: ReviewProductRequest) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/product-service/v2/app/products/review`,
      {
        headers: this.privateHeaders,
        method: 'POST',
        body: JSON.stringify(params),
      },
    )
  }

  public updateReview(params: UpdateReviewRequest) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/product-service/v2/app/products/review`,
      {
        headers: this.privateHeaders,
        method: 'PUT',
        body: JSON.stringify(params),
      },
    )
  }

  public getOrderReviews(orderId: string) {
    return fetcher<BaseResponse<OrderReviewsResponse>>(
      `${this.baseUrl}/product-service/v2/app/products/reviews/order/${orderId}/reviews`,
      {
        headers: this.privateHeaders,
        method: 'GET',
      },
    )
  }

  public deleteReview(id: string) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/product-service/v2/app/products/review/${id}`,
      {
        headers: this.privateHeaders,
        method: 'DELETE',
      },
    )
  }

  public getProductWarehouses(request: GetProductWarehousesRequest) {
    return fetcher<BaseResponse<ProductWarehouse[] | boolean>>(
      `${this.baseUrl}/product-service/v1/app/products/sku/warehouses`,
      {
        headers: this.privateHeaders,
        method: 'POST',
        body: JSON.stringify(request),
      },
    )
  }

  public getAllIndustries() {
    return fetcher<BaseResponse<GetProductIndustriesResponse>>(
      `${this.baseUrl}/product-service/v1/public/industries?PageNumber=1&PageSize=1000`,
      {
        headers: this.privateHeaders,
        method: 'GET',
      },
    )
  }

  public getPDPPosts(postId: string) {
    return fetcher<BaseResponse<PostDetailData>>(
      `${this.baseUrl}/product-service/v1/public/pdps/posts/${postId}`,
      {
        headers: this.headers,
      },
    )
  }
}

const productService = new ProductService()

export { productService }
