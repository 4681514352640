import { Client } from 'libs/apis'
import fetcher from 'libs/fetcher'
import {
  BaseResponse,
  GetPdpsResponseItem,
  GetFeaturedPdpsResponseItem,
  GetPdpDetailResponse,
} from 'types/schema'
import qs from 'query-string'

class PdpService extends Client {
  public getPdps(params: {
    pageSize?: number
    pageNumber?: number
    searchTerm?: string
  }) {
    return fetcher<BaseResponse<GetPdpsResponseItem[]>>(
      `${this.baseUrl}/product-service/v1/app/pdp-mall/pdps?${qs.stringify(
        params,
      )}`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public getFeaturedPdps(params: { PageNumber?: number; PageSize?: number }) {
    return fetcher<BaseResponse<GetFeaturedPdpsResponseItem[]>>(
      `${
        this.baseUrl
      }/product-service/v1/app/pdp-mall/featured-pdps?${qs.stringify(params)}`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public getPdpDetail(pdpId: string) {
    return fetcher<BaseResponse<GetPdpDetailResponse>>(
      `${this.baseUrl}/product-service/v1/app/pdp-mall/${pdpId}/pdp`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public followPdp(pdpId: string) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/product-service/v1/app/pdp-mall/${pdpId}/follow`,
      {
        headers: this.privateHeaders,
        method: 'PUT',
      },
    )
  }

  public unFollowPdp(pdpId: string) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/product-service/v1/app/pdp-mall/${pdpId}/unfollow`,
      {
        headers: this.privateHeaders,
        method: 'PUT',
      },
    )
  }
}

const pdpService = new PdpService()

export { pdpService }
