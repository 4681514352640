import { IconCheckCircle } from 'components/icons/components/IconCheckCircle'
import { useTranslations } from 'next-intl'
import cx from 'classnames'
import { IconWarning } from 'components/icons/components/IconWarning'
import { IconExclamationCircle } from 'components/icons/components/IconExclamationCircle'
import { IconErrorCircle } from 'components/icons/components/IconErrorCircle'
import { ReactNode } from 'react'
import { Modal, ModalContent, ModalTitle } from '../Modal'
import { Text, TextProps } from '../Text'

export type ConfirmType = 'base' | 'success' | 'warning' | 'info' | 'error'

interface Props {
  title: string
  message: React.ReactNode
  isOpen: boolean
  onClose: () => void
  onCancel?: () => void
  onConfirm?: () => void
  cancelText?: string
  confirmText?: string
  isDanger?: boolean
  type?: ConfirmType
  isReversed?: boolean
  className?: string
  messageClassName?: string
  messageAs?: TextProps['as']
  isConfirmDisabled?: boolean
  InputConfirm?: ReactNode
}

export function Confirm(props: Props) {
  const t = useTranslations()
  const {
    cancelText = t('confirm_cancel_text'),
    confirmText = t('confirm_confirm_text'),
    isOpen,
    message,
    onCancel,
    onClose,
    onConfirm,
    title,
    isReversed,
    type = 'base',
    isDanger = false,
    messageAs,
    className,
    messageClassName,
    isConfirmDisabled,
    InputConfirm,
  } = props

  const hasConfirmButton = typeof onConfirm === 'function'
  const hasCancelButton = typeof onCancel === 'function'

  const buttonClassname = cx(
    'h-14 inline-flex font-medium items-center justify-center text-lg focus:bg-gray-200 ring-0 focus:outline-none',
    { 'w-1/2': hasConfirmButton, 'w-full': !hasConfirmButton },
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent
        size="max-w-xs"
        className={cx('text-center !rounded-2xl !pb-0', className)}
      >
        {type === 'success' ? (
          <IconCheckCircle className="text-success inline-flex mb-2 text-4xl" />
        ) : null}

        {type === 'info' ? (
          <IconExclamationCircle className="text-blue-500 inline-flex mb-2 text-4xl" />
        ) : null}

        {type === 'error' ? (
          <IconErrorCircle className="text-error inline-flex mb-2 text-4xl" />
        ) : null}

        {type === 'warning' ? (
          <IconWarning className="text-warning inline-flex mb-2 text-4xl" />
        ) : null}
        <ModalTitle>{title}</ModalTitle>
        <Text
          as={messageAs}
          textColor="1100"
          className={cx(
            'mb-5',
            {
              'mt-4': type === 'base',
              'mt-2': type !== 'base',
            },
            messageClassName,
          )}
        >
          {message}
        </Text>
        {InputConfirm}
        <div
          className={cx(
            'flex border-t border-gray-500 -mx-5 rounded-b-2xl overflow-hidden',
            { 'flex-row-reverse': isReversed },
          )}
        >
          {hasCancelButton ? (
            <button
              className={cx(buttonClassname, 'text-primary flex-1')}
              onClick={onCancel}
            >
              {cancelText}
            </button>
          ) : null}

          {hasConfirmButton && hasCancelButton ? (
            <div className="w-px flex-none h-14 bg-gray-500" />
          ) : null}

          {hasConfirmButton ? (
            <button
              className={cx(buttonClassname, 'flex-1', {
                'text-primary': !isDanger,
                'text-error': isDanger,
              })}
              disabled={!!isConfirmDisabled}
              onClick={onConfirm}
            >
              {confirmText}
            </button>
          ) : null}
        </div>
      </ModalContent>
    </Modal>
  )
}
