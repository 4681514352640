import React from 'react'
import cx from 'classnames'
import { IconExclamationCircle } from 'components/icons/components/IconExclamationCircle'
import { IconCheckCircle } from 'components/icons/components/IconCheckCircle'
import { useToastAlertContext } from './context'
import { getColorByScheme } from './ToastAlert'
import type { ToastScheme, ToastVariant } from '../Toast.types'

function getIcon(scheme: ToastScheme) {
  if (scheme === 'success') {
    return IconCheckCircle
  }

  return IconExclamationCircle
}

export const ToastAlertIcon = () => {
  const { scheme, variant } = useToastAlertContext()

  const Icon = getIcon(scheme)

  return (
    <Icon
      className={cx(
        'text-xl inline-flex mr-4 flex-none',
        getIconColor(scheme, variant),
      )}
    />
  )
}

function getIconColor(scheme: ToastScheme, variant: ToastVariant) {
  if (variant === 'outlined') {
    return getColorByScheme({ scheme, variant: 'normal', prop: 'text' })
  }

  if (variant === 'solid') return 'text-white'

  return getColorByScheme({ scheme, variant: 'dark', prop: 'text' })
}
