import { ROUTES } from './routes'

export type upgradeAccountOptions = 'collaboration' | 'newAgent'

export const UPGRADE_ACCOUNT_OPTION: Record<string, upgradeAccountOptions> = {
  COLLABORATION: 'collaboration',
  AGENT: 'newAgent',
}

export const trialExpiredVisibleViews = [
  ROUTES.UPGRADE_ACCOUNT,
  ROUTES.UPGRADE_ACCOUNT_LEARN_MORE(),
]
