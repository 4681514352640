// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import { IS_DEV } from './src/utils/dev'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const BUILD_ENV = String(process.env.NEXT_PUBLIC_BUILD_ENV || 'development')

Sentry.init({
  enabled: !IS_DEV,
  dsn:
    SENTRY_DSN ||
    'https://aba8d5ba20e2496d9b58ce9080c1116d@o1348536.ingest.sentry.io/6770610',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0,
  ignoreErrors: ['zaloJSV2'],
  environment: BUILD_ENV,
  hideSourceMaps: true,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
