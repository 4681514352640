// NOTE: without destructuring will cause failure to fetch api on production
// eslint-disable-next-line prefer-destructuring
export const GA_TRACKING_ID = process.env.GA_TRACKING_ID

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
  window.gtag('config', GA_TRACKING_ID, {
    page_path: url,
  })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce
export interface GtagItem {
  id?: string | number
  name?: string
  list_name?: string
  brand?: string
  category?: string
  variant?: string
  list_position?: number
  quantity?: number
  price?: string | number
}

export type GtagAction =
  | 'select_content'
  | 'add_to_wishlist'
  | 'view_item'
  | 'add_to_cart'
  | 'remove_from_cart'
  | 'begin_checkout'
  | 'checkout_progress'
  | 'set_checkout_opwion'
  | 'purchase'
  | 'refund'
  | 'search'
  | 'customer_detail_co_ku_started'
  | 'lead_detail_co_ku_started'
  | 'order_list_co_ku_started'
  | 'customer_detail_co_ku_success'
  | 'lead_detail_co_ku_success'
  | 'order_list_co_ku_success'
  | 'open_ku_invoice'
  | 'take_ku_invoice'
  | 'save_ku_invoice_success'
  | 'share_ku_invoice_success'
  | 'pro_get_referral_link_started'
  | 'od_get_referral_link_started'
  | 'pro_copy_referral_link_1'
  | 'pro_copy_referral_link_2'
  | 'od_copy_referral_link_1'
  | 'od_copy_referral_link_2'
  | 'pro_share_mes_link_1'
  | 'pro_share_mes_link_2'
  | 'pro_share_zalo_link_1'
  | 'pro_share_zalo_link_2'
  | 'pro_share_sms_link_1'
  | 'pro_share_sms_link_2'
  | 'pro_share_other_link_1'
  | 'pro_share_other_link_2'
  | 'od_share_mes_link_1'
  | 'od_share_mes_link_2'
  | 'od_share_zalo_link_1'
  | 'od_share_zalo_link_2'
  | 'od_share_sms_link_1'
  | 'od_share_sms_link_2'
  | 'od_share_other_link_1'
  | 'od_share_other_link_2'
  | 'regis_sponsor_started'
  | 'regis_sponsor_continue'
  | 'regis_sponsor_signup'
  | 'regis_sponsor_submit_otp'
  | 'regis_select_account_started'
  | 'regis_update_account_success'
  | 'agent_select_account_started'
  | 'regis_active_acc_started'
  | 'regis_active_acc_failed'
  | 'regis_active_acc_success'
  | 'create_kungfu_remove_info_lead'

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export function event({
  action,
  values,
}: {
  action: GtagAction
  values?: Record<string, any>
}) {
  if (GA_TRACKING_ID) {
    window.gtag('event', action, {
      ...values,
      user_id: GA_TRACKING_ID,
    })
  } else if (process.env.GA_DEBUG_ENABLE === 'true') {
    // eslint-disable-next-line no-console
    console.log({ action, values })
  }
}
