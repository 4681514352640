import { Client } from 'libs/apis'
import fetcher from 'libs/fetcher'
import {
  BaseResponse,
  NotificationCategoryResponse,
  NotificationDetail,
  NotificationsResponse,
} from 'types/schema'

class NotificationService extends Client {
  public getNotificationCategory() {
    return fetcher<BaseResponse<NotificationCategoryResponse[]>>(
      `${this.baseUrl}/notifier-service/v1/app/notifier/categories`,
      {
        headers: this.formDataHeaders,
        method: 'GET',
      },
    )
  }

  public getNotifications(params: any) {
    return fetcher<BaseResponse<NotificationsResponse[]>>(
      `${this.baseUrl}/notifier-service/v1/app/notifier/notifications`,
      {
        headers: this.privateHeaders,
        method: 'POST',
        body: JSON.stringify(params),
      },
    )
  }

  public markReadNotification(params: { type: number; id: string }) {
    const { type, id } = params
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/notifier-service/v1/app/notifier/${id}/read`,
      {
        headers: this.privateHeaders,
        method: 'PUT',
        body: JSON.stringify({ type }),
      },
    )
  }

  public markReadAllNotification(categoryId: number | null) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/notifier-service/v1/app/notifier/read-all?categoryId=${categoryId}`,
      {
        headers: this.privateHeaders,
        method: 'PUT',
      },
    )
  }

  public getNotificationDetail(params: string) {
    return fetcher<BaseResponse<NotificationDetail>>(
      `${this.baseUrl}/notifier-service/v1/app/notifier/announcement/${params}`,
      {
        headers: this.formDataHeaders,
        method: 'GET',
      },
    )
  }

  public deleteNotification(notificationId: string) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/notifier-service/v1/app/notifier/${notificationId}`,
      {
        headers: this.privateHeaders,
        method: 'DELETE',
      },
    )
  }
}

const notificationService = new NotificationService()

export { notificationService }
