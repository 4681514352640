import { notificationService } from 'api'
import { useFetchWithCache } from 'hooks/useFetchWithCache'

const KEY = 'GET_NOTIFY_CATEGORY'

export function useFetchNotificationCategories(disabled = false) {
  const { data, ...rest } = useFetchWithCache(disabled ? null : [KEY], () =>
    notificationService.getNotificationCategory(),
  )

  return {
    categories: data?.data ?? [],
    ...rest,
  }
}
