import React from 'react'
import cx from 'classnames'
import { Text, TextProps } from 'components/ui/Text'
import { useToastAlertContext } from './context'
import { getColorByScheme } from './ToastAlert'
import { ToastScheme, ToastVariant } from '../Toast.types'

export const ToastAlertContent = ({
  children,
  className,
  ...props
}: Omit<TextProps, 'as'>) => {
  const { scheme, variant } = useToastAlertContext()

  return (
    <Text
      as="p"
      textColor="none"
      className={cx('text-sm', getTextColor(scheme, variant), className)}
      {...props}
    >
      {children}
    </Text>
  )
}

function getTextColor(scheme: ToastScheme, variant: ToastVariant) {
  if (scheme === 'gray') return 'text-v2-gray-50'

  if (variant === 'solid') return 'text-white'

  if (variant === 'subtle') {
    return getColorByScheme({ scheme, variant: 'dark', prop: 'text' })
  }

  return 'text-v2-gray-900'
}
