import React from 'react'
import cx from 'classnames'
import { IconSpinner } from 'components/icons/components/IconSpinner'
import { forwardRefWithAs } from 'utils/render'
import { BaseButton, BaseButtonProps } from '../BaseButton'
import { ButtonScheme, ButtonVariant } from '../BaseButton/types'
import { getAppearanceButtonStyles } from '../BaseButton/utils'

export interface IconButtonProps extends BaseButtonProps {
  variant?: ButtonVariant
  scheme?: ButtonScheme
  loading?: boolean
  disabled?: boolean
  label: string
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  iconClassName?: string
  asLabel?: boolean
  count?: number
}

function getButtonStyles({
  loading,
  asLabel,
  variant,
  size,
}: Pick<IconButtonProps, 'asLabel' | 'size' | 'loading' | 'size' | 'variant'>) {
  const classNames = ['relative']
  classNames.push('rounded-md')

  if (loading || asLabel) {
    classNames.push('cursor-default')
  }

  if (size === 'xl') {
    classNames.push('text-v2-2xl')
  } else if (size === 'lg') {
    classNames.push('text-v2-xl')
  } else if (size === 'md') {
    classNames.push('text-v2-lg')
  } else if (size === 'sm') {
    classNames.push('text-v2-md')
  } else {
    classNames.push('text-v2-nm')
  }

  if (variant === 'link') {
    classNames.push('h-10 w-10')
  } else if (size === 'xl') {
    classNames.push('h-14 w-14')
  } else if (size === 'lg') {
    classNames.push('h-12 w-12')
  } else if (size === 'md') {
    classNames.push('h-10 w-10')
  } else if (size === 'sm') {
    classNames.push('h-8 w-8')
  } else {
    classNames.push('h-7 w-7')
  }

  return classNames
}

export const IconButton = forwardRefWithAs<'button', IconButtonProps>(
  (
    {
      Icon,
      loading = false,
      label,
      className,
      iconClassName,
      asLabel = false,
      count,
      scheme = 'light',
      variant = 'link',
      size = variant === 'link' ? 'xl' : 'md',
      ...props
    },
    ref,
  ) => {
    let children

    if (loading) {
      children = <IconSpinner className={iconClassName} />
    } else if (Icon) {
      children = <Icon width="1em" height="1em" className={iconClassName} />
    }

    const passedInProps = {
      ...props,
      scheme,
      variant,
      size,
      asLabel,
      hasIcon: true,
    }

    return (
      <BaseButton
        ref={ref}
        aria-label={label}
        {...props}
        as={asLabel ? 'span' : props.as}
        className={cx(
          className,
          getButtonStyles(passedInProps),
          getAppearanceButtonStyles(passedInProps),
        )}
      >
        {children}
        {count ? (
          <span
            aria-hidden
            className="text-[10px] absolute -right-1 -top-px bg-red-500 min-w-[16px] text-white rounded-full h-4 px-1 flex items-center justify-center"
          >
            {count >= 100 ? '99+' : count}
          </span>
        ) : null}
      </BaseButton>
    )
  },
)
