import { Client } from 'libs/apis'
import fetcher from 'libs/fetcher'
import qs from 'query-string'
import {
  BaseResponse,
  LoginResponseData,
  GenerateForgotPasswordOTPResponseData,
  ResetPasswordRequest,
  PhoneLoginRequest,
  GenerateOTPRequest,
} from 'types/schema'

class IdentityService extends Client {
  public login(params: { UserName: string; Password: string }) {
    return fetcher<BaseResponse<LoginResponseData>>(
      `${this.baseUrl}/identity-service/v1/identity/get-token`,
      {
        headers: this.headers,
        method: 'POST',
        body: JSON.stringify(params),
      },
    )
  }

  public refreshToken(params: { refreshToken: string }) {
    return fetcher<BaseResponse<LoginResponseData>>(
      `${this.baseUrl}/identity-service/v1/identity/refresh-token`,
      {
        headers: this.headers,
        method: 'POST',
        body: JSON.stringify({
          Refreshtoken: params.refreshToken,
        }),
      },
    )
  }

  public generateForgotPasswordOtpV1(username: string) {
    return fetcher<BaseResponse<GenerateForgotPasswordOTPResponseData>>(
      `${this.baseUrl}/identity-service/v1/identity/forgot-password`,
      {
        headers: this.headers,
        method: 'POST',
        body: JSON.stringify({ username }),
      },
    )
  }

  public resetPasswordV1(request: ResetPasswordRequest) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/identity-service/v1/identity/reset-password`,
      {
        headers: this.headers,
        method: 'POST',
        body: JSON.stringify(request),
      },
    )
  }

  public generatePhoneLoginOtpV1(PhoneNumber: string) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/identity-service/v1/identity/generate-otp`,
      {
        headers: this.headers,
        method: 'POST',
        body: JSON.stringify({ PhoneNumber }),
      },
    )
  }

  public generateForgotPasswordOtpV2(params: GenerateOTPRequest) {
    return fetcher<BaseResponse<GenerateForgotPasswordOTPResponseData>>(
      `${this.baseUrl}/identity-service/v2/app/identity/password/forgot`,
      {
        headers: this.headers,
        method: 'POST',
        body: JSON.stringify(params),
      },
    )
  }

  public resetPasswordV2(request: ResetPasswordRequest) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/identity-service/v2/app/identity/password/forgot`,
      {
        headers: this.headers,
        method: 'PUT',
        body: JSON.stringify(request),
      },
    )
  }

  public generateOTP(params: GenerateOTPRequest) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/identity-service/v2/app/identity/token/otp/generate`,
      {
        headers: this.headers,
        method: 'POST',
        body: JSON.stringify(params),
      },
    )
  }

  public verifyOTP(params: {
    PhoneNumber?: string
    OTPCode?: string
    dialCode?: string
  }) {
    return fetcher<BaseResponse<LoginResponseData>>(
      `${this.baseUrl}/identity-service/v2/app/identity/token/otp/verify`,
      {
        headers: this.headers,
        method: 'POST',
        body: JSON.stringify(params),
      },
    )
  }

  public phoneLogin(request: PhoneLoginRequest) {
    return fetcher<BaseResponse<LoginResponseData>>(
      `${this.baseUrl}/identity-service/v1/identity/verify-otp`,
      {
        headers: this.headers,
        method: 'POST',
        body: JSON.stringify(request),
      },
    )
  }

  public changePassword(params: { OldPassword: string; NewPassword: string }) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/identity-service/v1/identity/change-password`,
      {
        headers: this.privateHeaders,
        method: 'PUT',
        body: JSON.stringify(params),
      },
    )
  }

  public getPhoneAndEmailFromPhone(params: {
    phone?: string
    username?: string
    dialCode?: string
  }) {
    return fetcher<
      BaseResponse<{
        phone: string
        email: string
        dialCode?: string
        countryCode?: string
      }>
    >(
      `${
        this.baseUrl
      }/identity-service/v2/app/identity/token/otp/receiver?${qs.stringify(
        params,
      )}`,
      {
        headers: this.privateHeaders,
      },
    )
  }
}

const identityService = new IdentityService()

export { identityService }
