import cx from 'classnames'

interface GetInputAppearanceProps {
  disabled?: boolean
  invalid?: boolean
  hasBorder?: boolean
}

export function getInputAppearance(props: GetInputAppearanceProps) {
  const { disabled, invalid, hasBorder } = props

  const classNames = cx(
    'focus:outline-none appearance-none !ring-0 transition duration-200',
    { border: hasBorder },
    invalid
      ? 'border-error text-v2-danger-400 focus:border-v2-danger-400 placeholder:text-v2-danger-400 bg-white'
      : {
          'bg-white hover:border-v2-gray-100 border-v2-gray-100 text-v2-gray-900 placeholder:text-v2-gray-200 focus:border-primary text-gray-1100':
            !disabled,
          'border-v2-gray-200 bg-v2-gray-25 text-v2-gray-200 opacity-100 text-fill-gray-700':
            disabled,
        },
  )

  return classNames
}

export type Truncate = boolean | '1' | '2' | '3' | '4'

export function getLineClamp(truncate: Truncate) {
  if (truncate === '1') {
    return 'line-clamp-1'
  }

  if (truncate === '2') {
    return 'line-clamp-2'
  }

  if (truncate === '3') {
    return 'line-clamp-3'
  }

  if (truncate === '4') {
    return 'line-clamp-4'
  }

  return ''
}

export type ColorCode =
  | 'Pr-Dark'
  | 'Pr-Normal'
  | 'Pr-Light'
  | 'Se-Dark'
  | 'Se-Normal'
  | 'Se-Light'
  | 'Su-Dark'
  | 'Su-Normal'
  | 'Su-Light'
  | 'Wa-Dark'
  | 'Wa-Normal'
  | 'Wa-Light'
  | 'Da-Dark'
  | 'Da-Normal'
  | 'Da-Light'
  | 'Gray-Dark'
  | 'Gray-Normal'
  | 'Gray-Light'
  | 'Black'
  | 'White'
  | 'Page-Bg'

export type BorderColorCode = 'Border-Normal' | 'Border-Dark' | 'Border-Light'

export function getTextColor(code: ColorCode) {
  if (code === 'Pr-Dark') {
    return 'text-v2-primary-600'
  }
  if (code === 'Pr-Normal') {
    return 'text-v2-primary-400'
  }
  if (code === 'Pr-Light') {
    return 'text-v2-primary-200'
  }
  if (code === 'Se-Dark') {
    return 'text-v2-secondary-600'
  }
  if (code === 'Se-Normal') {
    return 'text-v2-secondary-400'
  }
  if (code === 'Se-Light') {
    return 'text-v2-secondary-200'
  }
  if (code === 'Su-Dark') {
    return 'text-v2-success-600'
  }
  if (code === 'Su-Normal') {
    return 'text-v2-success-400'
  }
  if (code === 'Su-Light') {
    return 'text-v2-success-200'
  }
  if (code === 'Wa-Dark') {
    return 'text-v2-warning-600'
  }
  if (code === 'Wa-Normal') {
    return 'text-v2-warning-400'
  }
  if (code === 'Wa-Light') {
    return 'text-v2-warning-200'
  }
  if (code === 'Da-Dark') {
    return 'text-v2-danger-600'
  }
  if (code === 'Da-Normal') {
    return 'text-v2-danger-400'
  }
  if (code === 'Da-Light') {
    return 'text-v2-danger-200'
  }
  if (code === 'Gray-Dark') {
    return 'text-v2-gray-900'
  }
  if (code === 'Gray-Normal') {
    return 'text-v2-gray-600'
  }
  if (code === 'Gray-Light') {
    return 'text-v2-gray-400'
  }
  if (code === 'White') {
    return 'text-white'
  }
  if (code === 'Black') {
    return 'text-black'
  }
  if (code === 'Page-Bg') {
    return 'text-v2-gray-200'
  }
  return ''
}

export function getBackgroundColor(code: ColorCode) {
  if (code === 'Pr-Dark') {
    return 'bg-v2-primary-600'
  }
  if (code === 'Pr-Normal') {
    return 'bg-v2-primary-400'
  }
  if (code === 'Pr-Light') {
    return 'bg-v2-primary-200'
  }
  if (code === 'Se-Dark') {
    return 'bg-v2-secondary-600'
  }
  if (code === 'Se-Normal') {
    return 'bg-v2-secondary-400'
  }
  if (code === 'Se-Light') {
    return 'bg-v2-secondary-200'
  }
  if (code === 'Su-Dark') {
    return 'bg-v2-success-600'
  }
  if (code === 'Su-Normal') {
    return 'bg-v2-success-400'
  }
  if (code === 'Su-Light') {
    return 'bg-v2-success-200'
  }
  if (code === 'Wa-Dark') {
    return 'bg-v2-warning-600'
  }
  if (code === 'Wa-Normal') {
    return 'bg-v2-warning-400'
  }
  if (code === 'Wa-Light') {
    return 'bg-v2-warning-200'
  }
  if (code === 'Da-Dark') {
    return 'bg-v2-danger-600'
  }
  if (code === 'Da-Normal') {
    return 'bg-v2-danger-400'
  }
  if (code === 'Da-Light') {
    return 'bg-v2-danger-200'
  }
  if (code === 'Gray-Dark') {
    return 'bg-v2-gray-600'
  }
  if (code === 'Gray-Normal') {
    return 'bg-v2-gray-400'
  }
  if (code === 'Gray-Light') {
    return 'bg-v2-gray-200'
  }
  if (code === 'White') {
    return 'bg-white'
  }
  if (code === 'Black') {
    return 'bg-black'
  }
  if (code === 'Page-Bg') {
    return 'bg-v2-gray-200'
  }
  return ''
}
