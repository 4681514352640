import { Client } from 'libs/apis'
import fetcher from 'libs/fetcher'
import {
  BaseResponse,
  GetPromotionRequest,
  GetPromotionResponse,
  FindApplicablePromotionRequest,
  FindApplicablePromotionResponse,
  GetPromotionByProductIdRequest,
  GetPromotionByProductIdResponse,
} from 'types/schema'
import qs from 'query-string'

class PromotionService extends Client {
  public getPromotions(params: GetPromotionRequest) {
    return fetcher<BaseResponse<GetPromotionResponse[]>>(
      `${this.baseUrl}/promotion-service/v1/app/promotions?${qs.stringify(
        params,
      )}`,
      {
        headers: this.privateHeaders,
      },
    )
  }
  public getPromotionDetail(
    promotionId: string,
    params?: { detail?: boolean; warehouseId?: string },
  ) {
    return fetcher<BaseResponse<GetPromotionResponse>>(
      `${
        this.baseUrl
      }/promotion-service/v1/app/promotions/${promotionId}?${qs.stringify(
        params ?? {},
      )}`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public findApplicablePromotions(data: FindApplicablePromotionRequest[]) {
    return fetcher<BaseResponse<FindApplicablePromotionResponse[]>>(
      `${this.baseUrl}/promotion-service/v1/app/promotions/find-applicable-promotions`,
      {
        headers: this.privateHeaders,
        method: 'POST',
        body: JSON.stringify(data),
      },
    )
  }

  public getPromotionByProductId(params: GetPromotionByProductIdRequest) {
    return fetcher<BaseResponse<GetPromotionByProductIdResponse>>(
      `${
        this.baseUrl
      }/promotion-service/v1/app/promotions/products?${qs.stringify(params)}`,
      {
        headers: this.privateHeaders,
      },
    )
  }
}

const promotionService = new PromotionService()

export { promotionService }
