import { useDisclosure } from '@dwarvesf/react-hooks'
import { createContext } from '@dwarvesf/react-utils'
import { Confirm, ConfirmType } from 'components/ui/Confim'
import React, { useCallback, useState } from 'react'
import { WithChildren } from 'types/common'

interface AppAlertContextValues {
  openAlert: (params: AlertContentParams) => void
}

interface AlertContentParams {
  title: string
  message: React.ReactNode
  type: ConfirmType
  buttonText: string
}

const [Provider, useAppAlertContext] = createContext<AppAlertContextValues>({
  name: 'alert-context',
})

const AlertContextProvider = ({ children }: WithChildren) => {
  const [data, setData] = useState<AlertContentParams>({
    title: '',
    buttonText: '',
    message: '',
    type: 'success',
  })
  const { isOpen, onOpen, onClose } = useDisclosure()

  const openAlert = useCallback(
    (params: AlertContentParams) => {
      setData(params)
      onOpen()
    },
    [onOpen],
  )

  return (
    <>
      <Provider value={{ openAlert }}>{children}</Provider>
      <Confirm
        type={data.type}
        isOpen={isOpen}
        onClose={onClose}
        title={data.title}
        message={data.message}
        cancelText={data.buttonText}
        onCancel={onClose}
      />
    </>
  )
}

export { useAppAlertContext, AlertContextProvider }
