import 'react-datepicker/dist/react-datepicker.css'
import '../styles/index.css'
import 'swiper/css'
import 'swiper/css/pagination'
import React from 'react'
import App, { AppProps } from 'next/app'
import Script from 'next/script'
import { NextIntlProvider, useTranslations } from 'next-intl'
import { AuthContextProvider } from 'context/auth'
import { AlertContextProvider } from 'context/alert'
import { GA_TRACKING_ID, pageview } from 'libs/gtag'
import { Router, useRouter } from 'next/router'
import { Toaster } from 'components/ui/Toast'
import { CartContextProvider } from 'context/cart'
import { NotificationProvider } from 'context/notification'
import { captureException } from '@sentry/core'
import { isSSR } from '@dwarvesf/react-utils'
import { shouldPolyfill } from '@formatjs/intl-datetimeformat/should-polyfill'
import { IS_US_APP } from 'constants/dev'
import { getLocale, injectAppContext } from 'utils/dev'
import { OnboardingRedirector } from 'components/onboarding/OnboardingRedirector'
import { NavigationBar } from 'components/common/NavigationBar'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { DefaultSeo } from 'next-seo'

injectAppContext()

const queryClient = new QueryClient()

const loadTranslations = async () => {
  const locale = getLocale()
  const messages = await import(`../../translations/${locale}/all.json`)
  return messages
}

const translationMessages = await loadTranslations()

function IntlPolyfills() {
  const locale = IS_US_APP ? 'en-US' : 'vi-VN'

  const getFeatures = (locale: string) => {
    if (isSSR()) return []

    const isIntlSupported =
      typeof window.Intl !== 'undefined' &&
      !!window.Intl.Locale &&
      !!window.Intl.DateTimeFormat &&
      !!window.Intl.NumberFormat &&
      !!window.Intl.PluralRules &&
      !!window.Intl.RelativeTimeFormat

    const unsupportedLocale = shouldPolyfill(locale)

    return isIntlSupported && !unsupportedLocale
      ? []
      : [
          'Intl',
          'Intl.Locale',
          'Intl.DateTimeFormat',
          `Intl.DateTimeFormat.~locale.${locale}`,
          `Intl.NumberFormat`,
          `Intl.NumberFormat.~locale.${locale}`,
          'Intl.PluralRules',
          `Intl.PluralRules.~locale.${locale}`,
          'Intl.RelativeTimeFormat',
          `Intl.RelativeTimeFormat.~locale.${locale}`,
        ]
  }

  const polyfills = getFeatures(locale)

  if (polyfills.length === 0) return null

  return (
    <Script
      strategy="beforeInteractive"
      src={`https://polyfill.io/v3/polyfill.min.js?features=${polyfills.join(
        ',',
      )}`}
    />
  )
}

if (GA_TRACKING_ID) {
  Router.events.on('routeChangeComplete', (url) => pageview(url))
}

export const Noop = ({ children }: { children?: React.ReactNode }) => children

// Intersection Observer is the API used to determine if an element is inside the viewport or not.
// Browser support is really good - With Safari adding support in 12.1, all major browsers now support Intersection Observers natively.
// Add the polyfill, so it doesn't break on older versions of iOS and IE11.
async function loadIntersectionObserver() {
  try {
    if (typeof window.IntersectionObserver === 'undefined') {
      await import('intersection-observer')
    }
  } catch (error) {
    captureException(error)
  }
}

interface IntlPageProps {
  messages: Record<string, string>
  now: number
}

interface MyAppProps extends AppProps {
  messages: Record<string, string>
}

const SEORender = () => {
  const t = useTranslations()
  const { asPath } = useRouter()

  return (
    <DefaultSeo
      title={t('seo_title')}
      openGraph={{
        type: 'website',
        locale: 'vi_VN',
        description: t('seo_description'),
        site_name: t('seo_title'),
        title: t('seo_title'),
        url: `${process.env.NEXT_PUBLIC_SITE_URL}${asPath}`,
        images: [
          {
            width: 584,
            height: 584,
            url: `${process.env.NEXT_PUBLIC_SITE_URL}/og-image.jpeg`,
          },
        ],
      }}
      twitter={{
        cardType: 'summary',
      }}
      description={t('seo_description')}
    />
  )
}

class MyApp extends App<MyAppProps> {
  componentDidMount() {
    loadIntersectionObserver()
  }

  render() {
    const { Component, pageProps, messages } = this.props
    const Layout = (Component as any).Layout || Noop
    const hasNavbar = (Component as any).hasNavbar as boolean | undefined

    return (
      <NextIntlProvider
        // To achieve consistent date, time and number formatting
        // across the app, you can define a set of global formats.
        formats={{
          dateTime: {
            short: {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            },
          },
        }}
        // Messages can be received from individual pages or configured
        // globally in this module (`App.getInitialProps`). Note that in
        // the latter case the messages are available as a top-level prop
        // and not nested within `pageProps`.
        messages={translationMessages ?? {}}
        // Providing an explicit value for `now` ensures consistent formatting of
        // relative values regardless of the server or client environment.
        now={new Date((pageProps as IntlPageProps)?.now)}
        // Also an explicit time zone is helpful to ensure dates render the
        // same way on the client as on the server, which might be located
        // in a different time zone.
        timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
      >
        <SEORender />

        <AuthContextProvider>
          <AlertContextProvider>
            <CartContextProvider>
              <NotificationProvider>
                <QueryClientProvider client={queryClient}>
                  <OnboardingRedirector>
                    <Layout pageProps={pageProps}>
                      <Component {...pageProps} messages={messages} />
                    </Layout>
                    {hasNavbar && <NavigationBar />}
                  </OnboardingRedirector>
                </QueryClientProvider>
              </NotificationProvider>
            </CartContextProvider>
          </AlertContextProvider>
        </AuthContextProvider>

        <Toaster />

        <IntlPolyfills />
      </NextIntlProvider>
    )
  }
}

export default MyApp
