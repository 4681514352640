import { Client } from 'libs/apis'
import fetcher from 'libs/fetcher'
import { BaseResponse, UploadStreamItem } from 'types/schema'

class UploaderService extends Client {
  public createPermanentUpload(params: FormData) {
    return fetcher<BaseResponse<UploadStreamItem[]>>(
      `${this.baseUrl}/uploader-service/v1/uploader/temporary`,
      {
        headers: this.formDataHeaders,
        method: 'POST',
        body: params,
      },
    )
  }

  public createTemporaryUpload(params: FormData) {
    return fetcher<BaseResponse<UploadStreamItem[]>>(
      `${this.baseUrl}/uploader-service/v1/uploader/temporary/image/8?isGetPreviewUrl=true`,
      {
        headers: this.formDataHeaders,
        method: 'POST',
        body: params,
      },
    )
  }
}

const uploaderService = new UploaderService()

export { uploaderService }
