import { useFetchWithCache } from 'hooks/useFetchWithCache'
import {
  CartProductDetailResponse,
  GetCartAvailabilityRequest,
  ProductAvailability,
  InventoryItem,
  Inventory,
} from 'types/schema'
import { PRODUCT_TYPE_ENUM } from 'constants/enum'
import { orderService, productService } from 'api'
import { CartFormValuesV2 } from 'components/cart/OrderCartForm/types'
import { DistributiveOmit } from 'types/common'

export const SWR_KEYS_GET_OPEN_CART = 'GET_OPEN_CART'
export const SWR_KEYS_GET_PRODUCTS_CART = 'GET_PRODUCTS_CART'
export const SWR_KEYS_GET_CART_WAREHOUSE = 'GET_CART_WAREHOUSE'

export function useFetchOpenCart(disabled = false) {
  const { data, isFirstLoading, ...rest } = useFetchWithCache(
    disabled ? null : SWR_KEYS_GET_OPEN_CART,
    () => orderService.getOpenCart(),
  )

  return {
    cart: data?.data,
    isEmpty: !isFirstLoading && data?.data.inventories.length === 0,
    isFirstLoading,
    ...rest,
  }
}

export type OpenCartProduct = DistributiveOmit<InventoryItem, 'childItems'> &
  Omit<CartProductDetailResponse, 'productType' | 'childItems' | 'quantity'> & {
    cartId: string
    availability: number
    isBusinessSuspension: boolean
    isDiscontinued: boolean
    childItems?: ({
      quantity: number
      productSkuId: string
    } & Omit<CartProductDetailResponse, 'quantity'>)[]
  }

export function useFetchProductsCartV2(
  warehouseId: string,
  inventory: Inventory,
  fields: Exclude<CartFormValuesV2['items'], undefined>,
) {
  const { data, isFirstLoading, ...rest } = useFetchWithCache(
    [
      SWR_KEYS_GET_PRODUCTS_CART,
      warehouseId,
      JSON.stringify(inventory),
      JSON.stringify(fields),
    ],
    async () => {
      const productSkuIds = fields.reduce(
        (prev, current) => [
          ...prev,
          current.productSkuId,
          ...(current.childItems?.map(({ productSkuId }) => productSkuId) ??
            []),
        ],
        [] as string[],
      )
      const productsData = await productService.getProductDetails(productSkuIds)
      const productsMap = productsData?.data.reduce(
        (prev, item) => ({
          ...prev,
          [item.id]: item,
        }),
        {} as Record<string, CartProductDetailResponse>,
      )

      const cartAvailabilityRequest: GetCartAvailabilityRequest = {
        items: [
          {
            warehouseId,
            cartNormalProducts: fields
              .filter(
                (prod) => prod.productType !== PRODUCT_TYPE_ENUM.COMBO_PRODUCT,
              )
              .map((prod) => ({ skuId: prod.productSkuId })),
            cartComboProducts: fields
              .filter(
                (prod) => prod.productType === PRODUCT_TYPE_ENUM.COMBO_PRODUCT,
              )
              .map((prod) => ({
                skuId: prod.productSkuId,
                ComboProductSkuIds:
                  prod.childItems?.map(({ productSkuId }) => productSkuId) ??
                  [],
              })),
          },
        ],
      }

      const cartAvailability = await productService.getCartAvailability(
        cartAvailabilityRequest,
      )
      const productsAvailabilityMap: Record<string, ProductAvailability> =
        cartAvailability?.data
          .reduce(
            (prev, current) => [
              ...prev,
              ...(current.comboProductAvailability ?? []),
              ...(current.normalProductAvailability ?? []),
            ],
            [] as ProductAvailability[],
          )
          .reduce(
            (prev, current) => ({
              ...prev,
              [current.id]: current,
            }),
            {} as Record<string, ProductAvailability>,
          )

      const inventoryItemMap = inventory?.items.reduce((prev, current) => {
        const isPti = current.productType === 5
        const key = isPti
          ? `${current.productSkuId}-${current.sellingPrice}`
          : current.productSkuId

        return {
          ...prev,
          [key]: current,
        }
      }, {} as Record<string, InventoryItem>)

      const products = fields.map((productFields) => {
        const { productSkuId, childItems, ...rest } = productFields

        const product = productsMap[productSkuId]
        const availability =
          productsAvailabilityMap[productSkuId]?.availability ?? 0

        const isDiscontinued =
          productsAvailabilityMap[productSkuId]?.isDiscontinued ?? false

        const isBusinessSuspension =
          productsAvailabilityMap[productSkuId]?.isBusinessSuspension ?? false

        const inventoryItem =
          productFields.productType === 5
            ? inventoryItemMap[`${productSkuId}-${productFields.sellingPrice}`]
            : inventoryItemMap[productSkuId]

        return {
          ...rest,
          ...product,
          ...inventoryItem,
          childItems: childItems?.map(
            ({ productSkuId }) => productsMap[productSkuId],
          ),
          availability,
          productSkuId,
          isDiscontinued,
          isBusinessSuspension,
        } as OpenCartProduct
      })

      return products
    },
  )

  return {
    products: data,
    isFirstLoading,
    ...rest,
  }
}

export function useFetchCartWarehouse(warehouseId: string) {
  const { data, ...rest } = useFetchWithCache(
    [SWR_KEYS_GET_CART_WAREHOUSE, warehouseId],
    () => productService.getCartWarehouses([warehouseId]),
  )

  return {
    warehouse:
      data?.data && data?.data?.length > 0 ? data?.data?.[0] : undefined,
    ...rest,
  }
}
