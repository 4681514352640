import { getLocale, Locale } from 'utils/dev'

export const IS_PRODUCTION_BUILD =
  process.env.NEXT_PUBLIC_BASE_URL?.includes('api.droppii.') ||
  process.env.NEXT_PUBLIC_BASE_URL?.includes('apikh.droppii.')

export const IS_STAGING_BUILD =
  process.env.NEXT_PUBLIC_BASE_URL?.includes('apistg.droppii.com')

export const IS_DEVELOPMENT_BUILD =
  process.env.NEXT_PUBLIC_BASE_URL?.includes('apidev.droppii.com')

export const LOCALE = getLocale() as Locale

export const IS_US_APP = LOCALE === 'en-US'

export const IS_VN_APP = LOCALE === 'vi-VN'

// Cambodia
export const IS_KH_APP = LOCALE === 'km-KH'
