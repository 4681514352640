import { Client } from 'libs/apis'
import fetcher from 'libs/fetcher'
import {
  BaseResponse,
  GetSponsorResponseData,
  RegistryRequest,
  UserResponseData,
  GetUserGraphResponseData,
  GetBanksItem,
  VerifyUserOTPRequest,
  GetStoriesResponse,
  GetNewFeedResponseData,
  EContract,
  UserAgreementResponse,
  LoginResponseData,
  Contract,
  ContractList,
} from 'types/schema'
import { PartialDeep } from 'type-fest'
import qs from 'query-string'

class UserService extends Client {
  public verifyNotExistedUsername(username: string) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/user-service/v1/app/register/verify-username`,
      {
        headers: this.headers,
        method: 'POST',
        body: JSON.stringify({ username }),
      },
    )
  }

  public verifyNotExistedPhone(phone: string) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/user-service/v1/app/register/phone/exists`,
      {
        headers: this.headers,
        method: 'POST',
        body: JSON.stringify({ phone }),
      },
    )
  }

  public getSponsor(phoneOrUsername: string) {
    return fetcher<BaseResponse<GetSponsorResponseData>>(
      `${this.baseUrl}/user-service/v1/public/user/sponsor?phoneOrUsername=${phoneOrUsername}`,
      {
        headers: this.headers,
      },
    )
  }

  public generateRegistryOTP(params: { phone: string; dialCode?: string }) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/user-service/v1/app/register/generate-otp`,
      {
        headers: this.headers,
        method: 'POST',
        body: JSON.stringify(params),
      },
    )
  }

  public registry(request: RegistryRequest) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/user-service/v1/app/register`,
      {
        headers: this.headers,
        method: 'POST',
        body: JSON.stringify(request),
      },
    )
  }

  public getCurrentUser() {
    return fetcher<BaseResponse<UserResponseData>>(
      `${this.baseUrl}/user-service/v1/app/user`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public updateCurrentUser(
    user: PartialDeep<UserResponseData & { otp: string }>,
  ) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/user-service/v1/app/user`,
      {
        headers: this.privateHeaders,
        method: 'PATCH',
        body: JSON.stringify(user),
      },
    )
  }

  public updateCurrentUserV2(
    user: PartialDeep<UserResponseData & { otp: string }>,
  ) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/user-service/v2/app/user`,
      {
        headers: this.privateHeaders,
        method: 'PATCH',
        body: JSON.stringify(user),
      },
    )
  }

  public getUsers() {
    return fetcher<BaseResponse<GetUserGraphResponseData>>(
      `${this.baseUrl}/user-service/v1/app/user/graph`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public generateUserOTP() {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/user-service/v1/app/user/generate-otp`,
      {
        headers: this.privateHeaders,
        method: 'POST',
      },
    )
  }

  public verifyUserOTP(request: VerifyUserOTPRequest) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/user-service/v1/app/user/verify-otp`,
      {
        headers: this.privateHeaders,
        method: 'POST',
        body: JSON.stringify(request),
      },
    )
  }

  public getNewFeeds(params: {
    Page: number
    PageSize: number
    Includes: string[]
    Filters: Array<{
      fieldName: string
      fieldValue: string
      comparision: string
    }>
    Sorts: string[]
  }) {
    return fetcher<BaseResponse<GetNewFeedResponseData[]>>(
      `${this.baseUrl}/user-service/v1/app/news-feed`,
      {
        headers: this.privateHeaders,
        method: 'POST',
        body: JSON.stringify(params),
      },
    )
  }

  public likeNewFeed(params: { id?: string; reaction: number }) {
    const { id, ...rest } = params
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/user-service/v1/app/news-feed/${id}/reaction`,
      {
        headers: this.privateHeaders,
        method: 'PUT',
        body: JSON.stringify(rest),
      },
    )
  }

  public commentNewFeed(params: { id: string; content: string }) {
    const { id, ...rest } = params
    return fetcher<BaseResponse<any>>(
      `${this.baseUrl}/user-service/v1/app/news-feed/${id}/comment`,
      {
        headers: this.privateHeaders,
        method: 'POST',
        body: JSON.stringify(rest),
      },
    )
  }

  public getStories() {
    return fetcher<BaseResponse<GetStoriesResponse>>(
      `${this.baseUrl}/user-service/v1/app/story/user`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public viewStory(params: string) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/user-service/v1/app/story/view/${params}`,
      {
        headers: this.privateHeaders,
        method: 'PUT',
      },
    )
  }

  public checkViewedModal(params: number) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/user-service/v1/app/user/popup/${params}/viewed`,
      {
        headers: this.privateHeaders,
        method: 'PUT',
      },
    )
  }

  public getBanks() {
    return fetcher<BaseResponse<GetBanksItem[]>>(
      `${this.baseUrl}/user-service/v1/app/bank/banks`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public getContract() {
    return fetcher<BaseResponse<EContract[]>>(
      `${this.baseUrl}/user-service/v1/app/contract`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public getContractV2() {
    return fetcher<BaseResponse<ContractList>>(
      `${this.baseUrl}/user-service/v2/app/contracts/`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public getContractDetailV2(contractId: string) {
    return fetcher<BaseResponse<Contract>>(
      `${this.baseUrl}/user-service/v2/app/contracts/${contractId}`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public confirmContract(contractId: string) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/user-service/v1/app/contract/${contractId}/confirm`,
      {
        headers: this.privateHeaders,
        method: 'POST',
      },
    )
  }

  public signContract(contractId: string) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/user-service/v2/app/contracts/${contractId}/sign`,
      {
        headers: this.privateHeaders,
        method: 'POST',
      },
    )
  }

  public resendContractOTP(contractId: string) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/user-service/v1/app/contract/${contractId}/sendOtp`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public gapoRegister(params: { password: string }) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/user-service/v1/app/user/gapo`,
      {
        headers: this.privateHeaders,
        method: 'POST',
        body: JSON.stringify(params),
      },
    )
  }

  public deleteAccount(params: { otpCode: string }) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/user-service/v1/app/user/in-active`,
      {
        headers: this.privateHeaders,
        method: 'PUT',
        body: JSON.stringify(params),
      },
    )
  }

  public gapoUpgradePremium(autoRenew: boolean) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/user-service/v1/app/user/gapo/upgrade`,
      {
        headers: this.privateHeaders,
        method: 'PUT',
        body: JSON.stringify({
          IsAutoRenew: autoRenew,
        }),
      },
    )
  }

  public getUserAgreement() {
    return fetcher<BaseResponse<UserAgreementResponse[]>>(
      `${this.baseUrl}/user-service/v1/app/policy/user-agreement`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public acceptPolicy(params: { version: number; type: number }) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/user-service/v1/app/policy/agree?${qs.stringify(
        params,
      )}`,
      {
        method: 'POST',
        headers: this.privateHeaders,
      },
    )
  }

  public getUserIndustries(userId: string) {
    return fetcher<BaseResponse<string[]>>(
      `${this.baseUrl}/user-service/v1/public/user/${userId}/industries`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public editUserIndustries(userId: string, industryIds: string[]) {
    return fetcher<BaseResponse<string[]>>(
      `${this.baseUrl}/user-service/v1/public/user/${userId}/industries`,
      {
        method: 'PUT',
        headers: this.privateHeaders,
        body: JSON.stringify({
          industries: industryIds,
        }),
      },
    )
  }

  public activeAccount(params: string) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/user-service/v1/app/register/kungfu/${params}/activate`,
      {
        method: 'PUT',
        headers: this.privateHeaders,
      },
    )
  }

  public registerKungfu(params: RegistryRequest) {
    return fetcher<BaseResponse<LoginResponseData>>(
      `${this.baseUrl}/user-service/v1/app/register/kungfu`,
      {
        method: 'POST',
        body: JSON.stringify(params),
        headers: this.privateHeaders,
      },
    )
  }

  public getCountries() {
    return fetcher<BaseResponse<any>>(
      `${this.strapiBaseUrl}/api/dial-code?populate=*`,
    )
  }
}

const userService = new UserService()

export { userService }
