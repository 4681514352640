import { useAsyncEffect } from '@dwarvesf/react-hooks'
import { createContext } from '@dwarvesf/react-utils'
import { identityService, services, userService } from 'api'
import { toast } from 'components/ui/Toast'
import { AUTH_TOKEN_KEY, AUTH_REFRESH_TOKEN_KEY } from 'constants/common'
import { ROUTES } from 'constants/routes'
import { FetcherError } from 'libs/fetcher'
import { useRouter } from 'next/router'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { parseJWT } from 'utils/string'
import { diffTime } from 'utils/datetime'
import { WithChildren } from 'types/common'
import { UserResponseData, PhoneLoginRequest } from 'types/schema'
import emitter, { API_REQUEST } from 'libs/emitter'
import { useSWRConfig } from 'swr'
import { TRIAL_WELCOME_POPUP_SHOWN } from 'components/onboarding/OnboardingRedirector'
import { GaSourceFrom } from 'libs/gtag/registry'

const REFRESH_TOKEN_THRESHOLD_SECS = 60

interface AuthContextValues {
  isLogin: boolean
  isLoading?: boolean
  user?: UserResponseData
  gaSourceFrom: GaSourceFrom
  setGaSourceFrom: Dispatch<SetStateAction<GaSourceFrom>>
  login: (username: string, password: string) => Promise<any>
  setIsLogin: Dispatch<SetStateAction<boolean>>
  phoneLoginV1: (payload: PhoneLoginRequest) => Promise<any>
  phoneLoginV2: (payload: PhoneLoginRequest) => Promise<any>
  invalidateUser: () => ReturnType<typeof userService.getCurrentUser>
  logout: () => void
}

const [Provider, useAuthContext] = createContext<AuthContextValues>({
  name: 'auth',
})

const unprotectedPaths = [
  ROUTES.LOGIN,
  ROUTES.PHONE_LOGIN,
  ROUTES.REGISTRY,
  ROUTES.FORGOT_PASSWORD,
  ROUTES.REGISTER_PARTNER,
  '/pay/[orderId]',
  '/post/[postId]',
]

const AuthContextProvider = ({ children }: WithChildren) => {
  const { pathname, replace } = useRouter()
  const [isLogin, setIsLogin] = useState(!unprotectedPaths.includes(pathname))
  const [isLoading, setIsLoading] = useState(true)
  const [user, setUser] = useState<UserResponseData>()
  const refreshTokenTimeout = useRef<ReturnType<typeof window.setTimeout>>()
  const { cache } = useSWRConfig()
  // Analytics event
  const [gaSourceFrom, setGaSourceFrom] =
    useState<GaSourceFrom>('block_feature')

  const logout = useCallback(() => {
    services.clearAuthToken()
    window.localStorage.removeItem(AUTH_TOKEN_KEY)
    window.localStorage.removeItem(AUTH_REFRESH_TOKEN_KEY)
    setIsLogin(false)
    clearTimeout(refreshTokenTimeout.current)
    // The method supposed to exist but lack of type. Use optional chaining for a safe check
    // https://github.com/vercel/swr/issues/161#issuecomment-1079198998
    cache?.clear()
    setTimeout(() => {
      setUser(undefined)
    }, 300)
  }, [cache])

  const refreshToken = useCallback(async () => {
    // Token expired, try to get new jwt with refreshToken
    const refreshToken = window.localStorage.getItem(AUTH_REFRESH_TOKEN_KEY)

    // Refresh token not saved/init, just logout
    if (!refreshToken) {
      return logout()
    }

    let response
    try {
      response = await identityService.refreshToken({ refreshToken })

      // Can't get new token for some reasons .ie refreshToken expired
      if (!response?.data?.accessToken) {
        return logout()
      }
    } catch (err) {
      return logout()
    }

    // Refresh jwt successfully, set tokens
    services.setAuthToken(response.data.accessToken)
    window.localStorage.setItem(AUTH_TOKEN_KEY, response.data.accessToken)
    window.localStorage.setItem(
      AUTH_REFRESH_TOKEN_KEY,
      response.data.refreshToken,
    )
    setIsLogin(true)
  }, [logout])

  useEffect(() => {
    if (!window.localStorage.getItem(AUTH_TOKEN_KEY)) {
      setIsLogin(false)
    } else {
      setIsLogin(true)
    }
  }, [])

  useEffect(() => {
    if (!isLogin && !unprotectedPaths.includes(pathname)) {
      replace(ROUTES.LOGIN)
    }
  }, [isLogin, replace, pathname])

  useEffect(() => {
    const handleApiEvent = (event: any) => {
      if (event?.status === 401 || event?.status === 403) {
        logout()
      }
    }

    emitter.on(API_REQUEST, handleApiEvent)

    return () => {
      emitter.off(API_REQUEST, handleApiEvent)
    }
  }, [logout])

  const checkInvalidToken = useCallback(async () => {
    const authToken = window.localStorage.getItem(AUTH_TOKEN_KEY)
    if (!authToken) {
      return
    }

    const jwtObj = parseJWT(authToken)
    if (jwtObj === null) {
      return
    }

    const expiryTime = jwtObj?.exp as number
    if (!expiryTime) {
      return
    }

    const diffInDays = diffTime(expiryTime, 'days')

    // When setTimeout second param is over 24.8 days in seconds, it overflows and execute the setTimeout block immediately,
    // which leads to an infinite loop in scheduleCheck. That's why we need this check to prevent the overflow issue.
    if (diffInDays > 1) {
      return
    }

    const timeUntilExpiry = diffTime(expiryTime, 'seconds')

    const scheduleCheck = (timeLeftSeconds: number) => {
      window.clearTimeout(refreshTokenTimeout.current)

      refreshTokenTimeout.current = setTimeout(() => {
        checkInvalidToken()
      }, (timeLeftSeconds - REFRESH_TOKEN_THRESHOLD_SECS) * 1000)
    }

    if (timeUntilExpiry <= REFRESH_TOKEN_THRESHOLD_SECS) {
      await refreshToken()
      const newAuthToken = window.localStorage.getItem(AUTH_TOKEN_KEY)
      if (!newAuthToken) return

      const jwt = parseJWT(newAuthToken)
      const expiryTime = jwt?.exp as number
      scheduleCheck(diffTime(expiryTime, 'seconds'))
    } else {
      scheduleCheck(timeUntilExpiry)
    }
  }, [refreshToken])

  useAsyncEffect(async () => {
    if (!isLogin) {
      return
    }

    if (!window.localStorage.getItem(AUTH_TOKEN_KEY)) {
      return
    }

    await checkInvalidToken()

    try {
      const user = await userService.getCurrentUser()
      if (user?.data && user?.data?.isActive) {
        setUser(user.data)
        setIsLoading(false)
        // if account type is trial and no have trial navigate to payment kungfu page
      } else {
        logout()
      }
    } catch (error: any) {
      const { statusCode } = error as FetcherError

      if (statusCode === 401 || statusCode === 403) {
        return logout()
      }
    }
  }, [isLogin, checkInvalidToken])

  const login = useCallback(async (username: string, password: string) => {
    try {
      const { data, message } = await identityService.login({
        UserName: username,
        Password: password,
      })
      const token = data?.accessToken
      if (token) {
        services.setAuthToken(token)
        window.localStorage.setItem(AUTH_TOKEN_KEY, token)
        window.localStorage.setItem(AUTH_REFRESH_TOKEN_KEY, data.refreshToken)
        window.localStorage.setItem(TRIAL_WELCOME_POPUP_SHOWN, 'false')
        setIsLogin(true)
      } else {
        toast.error({ message })
      }
    } catch (error) {
      console.error(error)
    }
  }, [])

  const phoneLoginV1 = useCallback(async (payload: PhoneLoginRequest) => {
    try {
      const { data } = await identityService.phoneLogin(payload)
      const token = data?.accessToken
      if (token) {
        services.setAuthToken(token)
        window.localStorage.setItem(AUTH_TOKEN_KEY, token)
        window.localStorage.setItem(AUTH_REFRESH_TOKEN_KEY, data.refreshToken)
        setIsLogin(true)
      }
      return data
    } catch (error) {
      console.error(error)
      return false
    }
  }, [])

  const phoneLoginV2 = useCallback(async (payload: PhoneLoginRequest) => {
    try {
      const { data } = await identityService.verifyOTP(payload)
      const token = data?.accessToken
      if (token) {
        services.setAuthToken(token)
        window.localStorage.setItem(AUTH_TOKEN_KEY, token)
        window.localStorage.setItem(AUTH_REFRESH_TOKEN_KEY, data.refreshToken)
        setIsLogin(true)
      }
      return data
    } catch (error) {
      console.error(error)
      return false
    }
  }, [])

  const invalidateUser = useCallback(async () => {
    const response = await userService.getCurrentUser()
    if (response?.data) {
      setUser(response.data)
    }
    return response
  }, [])

  return (
    <Provider
      value={{
        isLogin,
        user,
        isLoading,
        gaSourceFrom,
        login,
        setIsLogin,
        phoneLoginV1,
        phoneLoginV2,
        logout,
        invalidateUser,
        setGaSourceFrom,
      }}
    >
      {children}
    </Provider>
  )
}

export { useAuthContext, AuthContextProvider, Provider }
