import { createContext } from '@dwarvesf/react-utils'
import { useFetchOpenCart } from 'hooks/cart/useFetchOpenCart'
import React, { useMemo } from 'react'
import { KeyedMutator } from 'swr'
import { WithChildren } from 'types/common'
import { BaseResponse, GetOpenCartResponse } from 'types/schema'
import { useAuthContext } from './auth'

export interface CartContextValues {
  refreshCart: KeyedMutator<BaseResponse<GetOpenCartResponse>>
  totalItemsInCart: number
  isCartEmpty: boolean
  cart?: GetOpenCartResponse
}

const [Provider, useCartContext] = createContext<CartContextValues>({
  name: 'cart-context',
})

const CartContextProvider = ({ children }: WithChildren) => {
  const { user } = useAuthContext()
  const {
    cart,
    mutate: refreshCart,
    isEmpty: isCartEmpty,
  } = useFetchOpenCart(!user)

  const totalItemsInCart = useMemo(() => {
    return (cart?.inventories || []).reduce((acc, cur) => {
      return (
        acc +
        cur.items.reduce((acc, { quantity }) => {
          return acc + (quantity || 0)
        }, 0)
      )
    }, 0)
  }, [cart])

  return (
    <Provider value={{ totalItemsInCart, refreshCart, isCartEmpty, cart }}>
      {children}
    </Provider>
  )
}

export { useCartContext, CartContextProvider }
