import { Button } from 'components/ui/Button'
import { Divider } from 'components/ui/Divider'
import { Modal, ModalContent, ModalTitle } from 'components/ui/Modal'
import { Text } from 'components/ui/Text'
import { ROUTES } from 'constants/routes'
import useFeatureFlag from 'hooks/useFeatureFlag'
import { useTranslations } from 'next-intl'
import Link from 'next/link'

interface ExpireTrialModalProps {
  isOpen: boolean
  onClose: () => void
}

export function ExpireTrialModal({ isOpen, onClose }: ExpireTrialModalProps) {
  const t = useTranslations()
  const usKungfuFlow = useFeatureFlag('course.us_kungfu_flow')

  const pathname = usKungfuFlow?.isEnabled
    ? ROUTES.COURSE
    : ROUTES.UPGRADE_ACCOUNT

  return (
    <Modal isOpen={isOpen} onClose={() => null}>
      <ModalContent size="max-w-sm" className="text-center !p-4 pt-8 w-[311px]">
        <div className="flex justify-center">
          <img
            width="155"
            height="127"
            src="/img/expire-trial.png"
            alt="Expire trial"
          />
        </div>
        <ModalTitle className="mt-2">
          <Text v2TextColor="Gray-Dark" fontSize="2xl">
            {t('trial_account_expired')}
          </Text>
        </ModalTitle>
        <Text v2TextColor="Gray-Dark" fontSize="md" className="my-4">
          {t('trial_account_expired_description')}
        </Text>

        <Divider color="400" className="mb-4" />

        <Link
          href={{
            pathname,
            query: {
              sourceFrom: 'trial_expies',
            },
          }}
        >
          <Button onClick={onClose} fullWidth>
            <Text v2TextColor="White" fontSize="md">
              {t('upgrade_now')}
            </Text>
          </Button>
        </Link>
      </ModalContent>
    </Modal>
  )
}
