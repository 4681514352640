import { useDisclosure } from '@dwarvesf/react-hooks'
import { ExpireTrialModal } from 'components/common/ExpireTrialModal'
import { WelcomeTrialModal } from 'components/common/WelcomeTrialModal'
import { trialExpiredVisibleViews } from 'constants/account-roles'
import { ACCOUNT_TYPE } from 'constants/enum'
import { ROUTES } from 'constants/routes'
import { useAuthContext } from 'context/auth'
import { FeatureFlag } from 'flags'
import useFeatureFlag from 'hooks/useFeatureFlag'
import { useRouter } from 'hooks/useRouter'
import { useCallback, useEffect } from 'react'
import { WithChildren } from 'types/common'

export const TRIAL_WELCOME_POPUP_SHOWN = 'droppii-trial-welcome-popup-shown'

const unprotectedPaths = [
  ROUTES.LOGIN,
  ROUTES.PHONE_LOGIN,
  ROUTES.FORGOT_PASSWORD,
  ROUTES.REGISTER_PARTNER,
  ROUTES.REGISTRY,
]

const V1Redirector = ({ children }: WithChildren) => {
  const { isLogin, user } = useAuthContext()
  const { pathname, replace } = useRouter()
  const usKungfuFlow = useFeatureFlag('course.us_kungfu_flow')

  const {
    isOpen: isOpenWelcomeModal,
    onClose: onCloseWelcomeModal,
    onOpen: onOpenWelcomeModal,
  } = useDisclosure()
  const {
    isOpen: isOpenExpireModal,
    onClose: onCloseExpireModal,
    onOpen: onOpenExpireModal,
  } = useDisclosure()

  useEffect(() => {
    if (isLogin && unprotectedPaths.includes(pathname)) {
      replace(ROUTES.HOME)
    }
  }, [isLogin, pathname, replace])

  useEffect(() => {
    if (!usKungfuFlow.isEnabled) {
      return
    }

    if (!user?.isTrialExpired && user?.accountType === ACCOUNT_TYPE.TRIAL) {
      onOpenWelcomeModal()
    }
    if (user?.isTrialExpired && user?.accountType === ACCOUNT_TYPE.TRIAL) {
      onOpenExpireModal()
    }
  }, [
    user?.isTrialExpired,
    user?.accountType,
    usKungfuFlow.isEnabled,
    onOpenExpireModal,
    onOpenWelcomeModal,
  ])

  return (
    <>
      <FeatureFlag flagKey="course.us_kungfu_flow">
        <WelcomeTrialModal
          isOpen={isOpenWelcomeModal}
          onClose={onCloseWelcomeModal}
        />
        <ExpireTrialModal
          isOpen={isOpenExpireModal}
          onClose={onCloseExpireModal}
        />
      </FeatureFlag>
      {children}
    </>
  )
}

const V2Redirector = ({ children }: WithChildren) => {
  const { isLogin, user, gaSourceFrom } = useAuthContext()
  const { pathname, replace } = useRouter()

  const customReplace = useCallback(
    (route: string, extraQuery?: Record<string, any>) => {
      if (
        (route === ROUTES.HOME && pathname !== ROUTES.HOME) ||
        !pathname.startsWith(route)
      ) {
        replace({
          pathname: route,
          query: { ...extraQuery },
        })
      }
    },
    [replace, pathname],
  )

  const {
    isOpen: isOpenWelcomeModal,
    onClose: onCloseWelcomeModal,
    onOpen: onOpenWelcomeModal,
  } = useDisclosure()
  const {
    isOpen: isOpenExpireModal,
    onClose: onCloseExpireModal,
    onOpen: onOpenExpireModal,
  } = useDisclosure()

  // Trial-allowed account not expired
  useEffect(() => {
    if (!isLogin || !user || pathname !== ROUTES.HOME) {
      return
    }

    const { isAllowedTrial, accountType, isTrialExpired } = user

    if (
      accountType === ACCOUNT_TYPE.TRIAL &&
      isAllowedTrial &&
      !isTrialExpired &&
      window.localStorage.getItem(TRIAL_WELCOME_POPUP_SHOWN) !== 'true'
    ) {
      window.localStorage.setItem(TRIAL_WELCOME_POPUP_SHOWN, 'true')
      // wait for the home page to be rendered completely after
      // routing from /onboarding-survey to show the modal
      setTimeout(() => onOpenWelcomeModal(), 1200)
    }
  }, [isLogin, onOpenWelcomeModal, pathname, user])

  // Trial-allowed account expired
  useEffect(() => {
    if (!isLogin || !user) {
      return
    }

    const { isAllowedTrial, accountType, isTrialExpired } = user

    const isInAllowedViews = trialExpiredVisibleViews.includes(pathname)

    if (
      accountType === ACCOUNT_TYPE.TRIAL &&
      isAllowedTrial &&
      isTrialExpired &&
      !isInAllowedViews
    ) {
      onOpenExpireModal()
    }
  }, [pathname, isLogin, onOpenExpireModal, user])

  // Trial not allowed
  useEffect(() => {
    if (!isLogin || !user) {
      return
    }
    const { accountType, isAllowedTrial } = user
    // account TRIAL and not in trial period => /upgrade/from-trial
    if (accountType === ACCOUNT_TYPE.TRIAL && !isAllowedTrial) {
      customReplace(ROUTES.UPGRADE_ACCOUNT_FROM_TRIAL, {
        sourceFrom: gaSourceFrom,
      })
    }
  }, [isLogin, user, customReplace, gaSourceFrom])

  useEffect(() => {
    if (!isLogin || !user) {
      return
    }
    // account TRIAL and in trial period
    const { accountType, isAllowedTrial, isTrialExpired } = user
    const isSurveyCompleted = true
    const isUnprotected = unprotectedPaths.includes(pathname)
    if (
      accountType === ACCOUNT_TYPE.TRIAL &&
      isAllowedTrial &&
      !isTrialExpired
    ) {
      // Done survey => /
      if (isSurveyCompleted && isUnprotected) {
        customReplace(ROUTES.HOME)
        return
      }

      if (!isSurveyCompleted) {
        // If not done survey yet => /onboarding-survey
        customReplace(ROUTES.ONBOARDING_SURVEY)
      }
    }
  }, [pathname, isLogin, user, customReplace])

  useEffect(() => {
    if (!isLogin || !user) {
      return
    }
    const { accountType } = user
    const isSurveyCompleted = true
    const isUnprotected = unprotectedPaths.includes(pathname)
    // COLLABORATOR or NEW_AGENT
    if (
      accountType === ACCOUNT_TYPE.COLLABORATOR ||
      accountType === ACCOUNT_TYPE.NEW_AGENT ||
      accountType === ACCOUNT_TYPE.STANDARD_AGENT
    ) {
      if (pathname.startsWith(ROUTES.REGISTRY)) {
        customReplace(ROUTES.ACTIVE_SUCCESS)
        return
      }

      if (!isSurveyCompleted && !pathname.startsWith(ROUTES.ACTIVE_SUCCESS)) {
        // If not done survey yet => /onboarding-survey
        customReplace(ROUTES.ONBOARDING_SURVEY)
      } else if (isUnprotected) {
        customReplace(ROUTES.HOME)
      }

      return
    }

    if (isUnprotected && !pathname.startsWith(ROUTES.REGISTRY)) {
      customReplace(ROUTES.HOME)
    }
  }, [isLogin, user, customReplace, pathname])

  return (
    <>
      <WelcomeTrialModal
        isOpen={isOpenWelcomeModal}
        onClose={onCloseWelcomeModal}
      />
      <ExpireTrialModal
        isOpen={isOpenExpireModal}
        onClose={onCloseExpireModal}
      />
      {children}
    </>
  )
}

export const OnboardingRedirector = ({ children }: WithChildren) => {
  const kungfuCourseV2 = useFeatureFlag('common.kungfu_course_v2')

  if (kungfuCourseV2.isEnabled) {
    return <V2Redirector>{children}</V2Redirector>
  }

  return <V1Redirector>{children}</V1Redirector>
}
