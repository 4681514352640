export type FontSize =
  | '3xl'
  | '2xl'
  | 'xl'
  | 'lg'
  | 'md'
  | 'nm'
  | 'sm'
  | 'xs'
  | '2xs'

export function getFontSize(size: FontSize) {
  if (size === '3xl') {
    return 'text-v2-3xl'
  }
  if (size === '2xl') {
    return 'text-v2-2xl'
  }
  if (size === 'xl') {
    return 'text-v2-xl'
  }
  if (size === 'lg') {
    return 'text-v2-lg'
  }
  if (size === 'md') {
    return 'text-v2-md'
  }
  if (size === 'nm') {
    return 'text-v2-nm'
  }
  if (size === 'sm') {
    return 'text-v2-sm'
  }
  if (size === 'xs') {
    return 'text-v2-xs'
  }
  return 'text-v2-2xs'
}
