import { Client } from 'libs/apis'
import fetcher from 'libs/fetcher'
import {
  BaseResponse,
  GetCustomerRequest,
  GetCustomerResponseData,
  GetCustomerAddressesResponseData,
  GetCustomerDetailResponseData,
  CreateCustomerParams,
  CreateCustomerCodeResponseData,
  CreateCustomerResponseData,
  GetCustomerNotesResponseData,
  AddressModel,
  AdministrativeAreaLevel,
  CreateCustomerAddressParams,
  CreateCustomerAddressParamsV2,
  CreateCustomerAddressResponseData,
  UpdateCustomerAddressParams,
  UpdateCustomerAddressParamsV2,
  GetCustomerSchedulesParams,
  GetCustomerScheduleData,
  CreateCustomerScheduleParams,
  UpdateCustomerScheduleParams,
  DeleteScheduleParams,
  GetMarketingPackage,
  GetLeadCategoryParams,
  GetLeadCategoryResponse,
  GetPlanningPackageResponse,
  GetOriginBankInfoResponse,
  GetHistoryPackageResponse,
  FetchPackageHistoryRequestType,
  FetchLeadListRequestType,
  GetMarketingCenterLeadsRequest,
  FetchLeadListResponseType,
  GetMarketingCenterLeadsResponse,
  LeadDetailResponse,
  TagResponse,
  SetLeadTagRequest,
  EditLeadEmailRequest,
  EditLeadInfoRequest,
  ForwardLeadRequest,
  CreateLeadNoteRequest,
  CreateLeadScheduleRequest,
  UpdateLeadScheduleRequest,
  FetchLeadSchedulesRequest,
  FetchLeadSchedulesResponse,
  CreateUSCustomerParams,
  DefaultUSAddress,
  PackagePaymentHistoryResponse,
  MarketingInstallAppRequest,
  SellerBalanceResponse,
  MarketingMetadataResponse,
  MarketingMetadataRequest,
  GetMarketingTransactionResponse,
  GetSellerBalanceResponse,
  FetchSellerSummaryRequestType,
  PackageHistoryV2,
  FetchPackageHistoryRequestTypeV2,
} from 'types/schema'
import {
  UpdateNoteRequest,
  DeleteNoteRequest,
  ForwardCustomerToDownlineRequest,
  AddCustomerNoteCommand,
  UpdateCustomerRequest,
} from 'types/service/marketing'
import qs from 'query-string'

class MarketingService extends Client {
  public getCustomers(request: GetCustomerRequest) {
    return fetcher<BaseResponse<GetCustomerResponseData[]>>(
      `${
        this.baseUrl
      }/marketing-service/v2/app/marketing/customers?${qs.stringify(request)}`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public getCustomerDetail(id: string) {
    return fetcher<BaseResponse<GetCustomerDetailResponseData>>(
      `${this.baseUrl}/marketing-service/v1/app/marketing/customers/${id}`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public updateCustomerInfo(params: UpdateCustomerRequest) {
    return fetcher<BaseResponse<GetCustomerDetailResponseData>>(
      `${this.baseUrl}/marketing-service/v1/app/marketing/customer`,
      {
        method: 'PUT',
        headers: this.privateHeaders,
        body: JSON.stringify(params),
      },
    )
  }

  public getCustomerAddresses(id: string) {
    return fetcher<
      BaseResponse<(GetCustomerAddressesResponseData | DefaultUSAddress)[]>
    >(
      `${this.baseUrl}/marketing-service/v1/app/marketing/customers/${id}/addresses`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public createCustomerCode() {
    return fetcher<BaseResponse<CreateCustomerCodeResponseData>>(
      `${this.baseUrl}/marketing-service/v1/app/marketing/customers/code`,
      {
        headers: this.privateHeaders,
        method: 'POST',
      },
    )
  }

  public createCustomer(body: CreateCustomerParams) {
    return fetcher<BaseResponse<CreateCustomerResponseData>>(
      `${this.baseUrl}/marketing-service/v1/app/marketing/customer`,
      {
        headers: this.privateHeaders,
        method: 'POST',
        body: JSON.stringify(body),
      },
    )
  }

  public createUSCustomer(body: CreateUSCustomerParams) {
    return fetcher<BaseResponse<CreateCustomerResponseData>>(
      `${this.baseUrl}/marketing-service/v2/app/marketing/customer`,
      {
        headers: this.privateHeaders,
        method: 'POST',
        body: JSON.stringify(body),
      },
    )
  }

  public forwardCustomer(body: Required<ForwardCustomerToDownlineRequest>) {
    return fetcher<BaseResponse<null>>(
      `${this.baseUrl}/marketing-service/v1/app/marketing/customer/forward`,
      {
        headers: this.privateHeaders,
        method: 'PUT',
        body: JSON.stringify(body),
      },
    )
  }

  public createCustomerNote(body: Required<AddCustomerNoteCommand>) {
    return fetcher<BaseResponse<any>>(
      `${this.baseUrl}/marketing-service/v1/app/marketing/customers/note`,
      {
        headers: this.privateHeaders,
        method: 'POST',
        body: JSON.stringify(body),
      },
    )
  }

  public getCustomerNote(noteId: string) {
    return fetcher<BaseResponse<GetCustomerNotesResponseData>>(
      `${this.baseUrl}/marketing-service/v1/app/marketing/notes/${noteId}`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public updateCustomerNote(body: UpdateNoteRequest) {
    return fetcher<BaseResponse<any>>(
      `${this.baseUrl}/marketing-service/v1/app/marketing/notes/${body.leadId}`,
      {
        headers: this.privateHeaders,
        method: 'PUT',
        body: JSON.stringify(body),
      },
    )
  }

  public getCustomerNotes(id: string) {
    return fetcher<BaseResponse<GetCustomerNotesResponseData[]>>(
      `${this.baseUrl}/marketing-service/v1/app/marketing/customers/${id}/notes`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public deleteCustomerNote(body: DeleteNoteRequest) {
    return fetcher<BaseResponse<any>>(
      `${this.baseUrl}/marketing-service/v1/app/marketing/notes/${body.leadId}`,
      {
        headers: this.privateHeaders,
        method: 'DELETE',
        body: JSON.stringify(body),
      },
    )
  }

  public getProvinces(withPassportIssuer: boolean = false) {
    const qs = new URLSearchParams({
      SortBy: 'Order',
      GetPassportIssuer: `${withPassportIssuer}`,
    })

    return fetcher<BaseResponse<AddressModel[]>>(
      `${
        this.baseUrl
      }/marketing-service/v1/app/marketing/addresses/provinces?${qs.toString()}`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public getDistricts(provinceId: string) {
    return fetcher<BaseResponse<AddressModel[]>>(
      `${this.baseUrl}/marketing-service/v1/app/marketing/addresses/provinces/${provinceId}/districts`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public getWards(districtId: string) {
    return fetcher<BaseResponse<AddressModel[]>>(
      `${this.baseUrl}/marketing-service/v1/app/marketing/addresses/districts/${districtId}/wards`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public getHamlets(wardId: string) {
    return fetcher<BaseResponse<AddressModel[]>>(
      `${this.baseUrl}/marketing-service/v1/app/marketing/addresses/wards/${wardId}/hamlets`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public getAdministrativeAreaLv1() {
    return fetcher<BaseResponse<AdministrativeAreaLevel[]>>(
      `${this.baseUrl}/marketing-service/v2/app/marketing/administrative-areas?pageSize=999`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public getAdministrativeAreaLv2(id: string) {
    return fetcher<BaseResponse<AdministrativeAreaLevel[]>>(
      `${this.baseUrl}/marketing-service/v2/app/marketing/administrative-areas/${id}/second-level?pageSize=999`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public createCustomerAddress(body: CreateCustomerAddressParams) {
    return fetcher<BaseResponse<CreateCustomerAddressResponseData>>(
      `${this.baseUrl}/marketing-service/v1/app/marketing/customers/address`,
      {
        headers: this.privateHeaders,
        method: 'POST',
        body: JSON.stringify(body),
      },
    )
  }

  public createCustomerAddressV2(body: CreateCustomerAddressParamsV2) {
    return fetcher<BaseResponse<CreateCustomerAddressResponseData>>(
      `${this.baseUrl}/marketing-service/v2/app/marketing/customers/address`,
      {
        headers: this.privateHeaders,
        method: 'POST',
        body: JSON.stringify(body),
      },
    )
  }

  public updateCustomerAddress(body: UpdateCustomerAddressParams) {
    return fetcher<BaseResponse<any>>(
      `${this.baseUrl}/marketing-service/v1/app/marketing/address`,
      {
        headers: this.privateHeaders,
        method: 'PUT',
        body: JSON.stringify(body),
      },
    )
  }

  public updateCustomerAddressV2(body: UpdateCustomerAddressParamsV2) {
    return fetcher<BaseResponse<any>>(
      `${this.baseUrl}/marketing-service/v2/app/marketing/address`,
      {
        headers: this.privateHeaders,
        method: 'PUT',
        body: JSON.stringify(body),
      },
    )
  }

  public deleteCustomerAddress(addressId: string, customerId: string) {
    return fetcher<BaseResponse<any>>(
      `${this.baseUrl}/marketing-service/v1/app/marketing/addresses/${addressId}`,
      {
        headers: this.privateHeaders,
        method: 'DELETE',
        body: JSON.stringify({ customerId }),
      },
    )
  }

  public getCustomerSchedules(id: string, params: GetCustomerSchedulesParams) {
    return fetcher<BaseResponse<GetCustomerScheduleData[]>>(
      `${
        this.baseUrl
      }/marketing-service/v1/app/marketing/customers/${id}/callbacks?${qs.stringify(
        params,
      )}`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public createCustomerSchedule(params: CreateCustomerScheduleParams) {
    return fetcher<BaseResponse<string>>(
      `${this.baseUrl}/marketing-service/v1/app/marketing/customers/callback`,
      {
        headers: this.privateHeaders,
        method: 'POST',
        body: JSON.stringify(params),
      },
    )
  }

  public updateSchedule(
    params: UpdateCustomerScheduleParams | UpdateLeadScheduleRequest,
  ) {
    return fetcher<BaseResponse<string>>(
      `${this.baseUrl}/marketing-service/v1/app/marketing/callback`,
      {
        headers: this.privateHeaders,
        method: 'PUT',
        body: JSON.stringify(params),
      },
    )
  }

  public completeSchedule(params: {
    scheduleId: string
    status: number
    leadId?: string
    customerId?: string
  }) {
    const { scheduleId, status, leadId, customerId } = params
    return fetcher<BaseResponse<string>>(
      `${this.baseUrl}/marketing-service/v1/app/marketing/callbacks/${scheduleId}/status`,
      {
        headers: this.privateHeaders,
        method: 'PUT',
        body: JSON.stringify({ customerId, leadId, status }),
      },
    )
  }

  public deleteSchedule(params: DeleteScheduleParams) {
    const { scheduleId, ...rest } = params
    return fetcher<BaseResponse<string>>(
      `${this.baseUrl}/marketing-service/v1/app/marketing/callbacks/${scheduleId}`,
      {
        headers: this.privateHeaders,
        method: 'DELETE',
        body: JSON.stringify(rest),
      },
    )
  }

  public getPlanningPackage() {
    return fetcher<BaseResponse<GetPlanningPackageResponse[]>>(
      `${this.baseUrl}/marketing-service/v1/app/marketing/lead-packages`,
      {
        headers: this.formDataHeaders,
        method: 'GET',
      },
    )
  }

  public getLeadCategory(params: GetLeadCategoryParams) {
    return fetcher<BaseResponse<GetLeadCategoryResponse[]>>(
      `${
        this.baseUrl
      }/marketing-service/v1/app/marketing/lead-categories?${qs.stringify(
        params,
      )}`,
      {
        headers: this.formDataHeaders,
        method: 'GET',
      },
    )
  }

  public getMarketingPackage() {
    return fetcher<BaseResponse<GetMarketingPackage[]>>(
      `${this.baseUrl}/marketing-service/v1/app/marketing/lead-registries`,
      {
        headers: this.formDataHeaders,
        method: 'GET',
      },
    )
  }

  public getMarketingPackageV2() {
    return fetcher<BaseResponse<GetMarketingPackage[]>>(
      `${this.baseUrl}/marketing-service/v2/app/marketing/lead-registries`,
      {
        headers: this.formDataHeaders,
        method: 'GET',
      },
    )
  }

  public updatePackageMarketing(params: any) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/marketing-service/v1/app/marketing/lead-of-day-registry`,
      {
        headers: this.privateHeaders,
        method: 'PUT',
        body: JSON.stringify(params),
      },
    )
  }

  public getOriginBankInfo() {
    return fetcher<BaseResponse<GetOriginBankInfoResponse>>(
      `${this.baseUrl}/marketing-service/v1/app/marketing/bank`,
      {
        headers: this.formDataHeaders,
        method: 'GET',
      },
    )
  }

  public registryPackagePlanning(params: any) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/marketing-service/v1/app/marketing/lead-category-buying`,
      {
        headers: this.privateHeaders,
        method: 'POST',
        body: JSON.stringify(params),
      },
    )
  }

  public registryPackagePlanningUS(params: any) {
    return fetcher<
      BaseResponse<{
        checkoutUrl: string
      }>
    >(
      `${this.baseUrl}/marketing-service/v2/app/marketing/lead-category-buying`,
      {
        headers: this.privateHeaders,
        method: 'POST',
        body: JSON.stringify(params),
      },
    )
  }

  public getPackageHistory(params: FetchPackageHistoryRequestType) {
    return fetcher<BaseResponse<GetHistoryPackageResponse>>(
      `${
        this.baseUrl
      }/marketing-service/v1/app/marketing/driven-lead-categories?${qs.stringify(
        params,
      )}`,
      {
        headers: this.formDataHeaders,
        method: 'GET',
      },
    )
  }

  public getPackageHistoryV2(params: FetchPackageHistoryRequestTypeV2) {
    return fetcher<BaseResponse<PackageHistoryV2[]>>(
      `${
        this.baseUrl
      }/marketing-service/v2/app/marketing/transaction-histories?${qs.stringify(
        params,
      )}`,
      {
        headers: this.formDataHeaders,
        method: 'GET',
      },
    )
  }

  public getMarketingTransaction(transactionId: string) {
    return fetcher<BaseResponse<GetMarketingTransactionResponse>>(
      `${this.baseUrl}/marketing-service/v2/app/marketing/transaction/${transactionId}`,
      {
        headers: this.formDataHeaders,
        method: 'GET',
      },
    )
  }

  public getLeadList(params: FetchLeadListRequestType) {
    return fetcher<BaseResponse<FetchLeadListResponseType[]>>(
      `${this.baseUrl}/marketing-service/v1/app/marketing/leads?${qs.stringify(
        params,
      )}`,
      {
        headers: this.formDataHeaders,
        method: 'GET',
      },
    )
  }

  public getMarketingCenterLeads(params: GetMarketingCenterLeadsRequest) {
    return fetcher<BaseResponse<GetMarketingCenterLeadsResponse>>(
      `${this.baseUrl}/marketing-service/v2/app/marketing/leads?${qs.stringify(
        params,
      )}`,
      {
        headers: this.formDataHeaders,
        method: 'GET',
      },
    )
  }

  public getLeadDetail(leadId: string, numOfNotes?: number | null) {
    const params = numOfNotes ? { displayNoteQuantity: numOfNotes } : {}
    return fetcher<BaseResponse<LeadDetailResponse>>(
      `${
        this.baseUrl
      }/marketing-service/v1/app/marketing/leads/${leadId}?${qs.stringify(
        params,
      )}`,
      {
        headers: this.formDataHeaders,
        method: 'GET',
      },
    )
  }

  public getTags() {
    return fetcher<BaseResponse<TagResponse[]>>(
      `${this.baseUrl}/marketing-service/v1/app/marketing/tags`,
      {
        headers: this.formDataHeaders,
        method: 'GET',
      },
    )
  }

  public getLeadSchedules(params: FetchLeadSchedulesRequest) {
    return fetcher<BaseResponse<FetchLeadSchedulesResponse[]>>(
      `${
        this.baseUrl
      }/marketing-service/v1/app/marketing/callbacks?${qs.stringify(params)}`,
      {
        headers: this.formDataHeaders,
        method: 'GET',
      },
    )
  }

  public setLeadTag(params: SetLeadTagRequest) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/marketing-service/v1/app/marketing/leads/tag`,
      {
        headers: this.privateHeaders,
        method: 'PUT',
        body: JSON.stringify(params),
      },
    )
  }

  public setLeadTags(leadId: string, tagIds: string[]) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/marketing-service/v2/app/marketing/leads/${leadId}/tags`,
      {
        headers: this.privateHeaders,
        method: 'PUT',
        body: JSON.stringify({ tagIds }),
      },
    )
  }

  public deleteNoteLead(params: { leadId: string; noteId: string }) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/marketing-service/v1/app/marketing/notes/${params?.noteId}`,
      {
        headers: this.privateHeaders,
        method: 'DELETE',
        body: JSON.stringify(params),
      },
    )
  }

  public editLeadEmail(params: EditLeadEmailRequest) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/marketing-service/v1/app/marketing/leads/email`,
      {
        headers: this.privateHeaders,
        method: 'PUT',
        body: JSON.stringify(params),
      },
    )
  }

  public editLeadInfo(params: EditLeadInfoRequest) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/marketing-service/v1/app/marketing/leads/info`,
      {
        headers: this.privateHeaders,
        method: 'PUT',
        body: JSON.stringify(params),
      },
    )
  }

  public forwardLead(params: ForwardLeadRequest) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/marketing-service/v1/app/marketing/lead-forwarding`,
      {
        headers: this.privateHeaders,
        method: 'POST',
        body: JSON.stringify(params),
      },
    )
  }

  public createLeadNote(params: CreateLeadNoteRequest) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/marketing-service/v1/app/marketing/leads/note`,
      {
        headers: this.privateHeaders,
        method: 'POST',
        body: JSON.stringify(params),
      },
    )
  }

  public createLeadSchedule(params: CreateLeadScheduleRequest) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/marketing-service/v1/back-office/marketing/leads/callback`,
      {
        headers: this.privateHeaders,
        method: 'POST',
        body: JSON.stringify(params),
      },
    )
  }

  public updateLeadNote(body: {
    header: string
    noteId: string
    leadId: string
    content: string
  }) {
    return fetcher<BaseResponse<any>>(
      `${this.baseUrl}/marketing-service/v1/app/marketing/notes/${body.noteId}`,
      {
        headers: this.privateHeaders,
        method: 'PUT',
        body: JSON.stringify(body),
      },
    )
  }

  public getPackagePaymentHistory(categoryId: string) {
    return fetcher<BaseResponse<PackagePaymentHistoryResponse[]>>(
      `${this.baseUrl}/marketing-service/v2/app/marketing/category/${categoryId}/transactions`,
      {
        headers: this.formDataHeaders,
        method: 'GET',
      },
    )
  }

  public getStripeLink(params: {
    leadRegistryId: string
    checkoutSessionId: string
  }) {
    return fetcher<BaseResponse<string>>(
      `${this.baseUrl}/marketing-service/v2/app/marketing/lead-registry/checkout-url`,
      {
        headers: this.privateHeaders,
        method: 'POST',
        body: JSON.stringify(params),
      },
    )
  }

  public marketingInstallApp(params: MarketingInstallAppRequest) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/marketing-service/v2/app/marketing/install-app/lead`,
      {
        headers: this.headers,
        method: 'POST',
        body: JSON.stringify(params),
      },
    )
  }

  public getKungfuLeads(params: FetchLeadListRequestType) {
    return fetcher<BaseResponse<any[]>>(
      `${
        this.baseUrl
      }/marketing-service/v2/app/marketing/kungfu-order/leads?${qs.stringify(
        params,
      )}`,
      {
        headers: this.formDataHeaders,
        method: 'GET',
      },
    )
  }

  public getSellerBalance() {
    return fetcher<BaseResponse<SellerBalanceResponse>>(
      `${this.baseUrl}/marketing-service/v2/app/marketing/seller/balance`,
      {
        headers: this.privateHeaders,
        method: 'GET',
      },
    )
  }

  public bookmarkLead(leadId: string, isBookmarked: boolean) {
    return fetcher<BaseResponse<null>>(
      `${this.baseUrl}/marketing-service/v2/app/marketing/leads/${leadId}/bookmarked`,
      {
        headers: this.privateHeaders,
        method: 'PUT',
        body: JSON.stringify({
          isMarked: isBookmarked,
        }),
      },
    )
  }

  public getMarketingMetadata(params: MarketingMetadataRequest) {
    return fetcher<BaseResponse<MarketingMetadataResponse>>(
      `${
        this.baseUrl
      }/marketing-service/v2/app/marketing/metadata?${qs.stringify(params)}`,
      {
        headers: this.privateHeaders,
        method: 'GET',
      },
    )
  }

  public suspendLeadMarketingPackage(categoryId: string) {
    return fetcher<BaseResponse<boolean>>(
      `${this.baseUrl}/marketing-service/v2/app/marketing/lead-categories/${categoryId}/suspend`,
      {
        headers: this.privateHeaders,
        method: 'PUT',
      },
    )
  }

  public topup(amount: number) {
    return fetcher<BaseResponse<GetMarketingTransactionResponse>>(
      `${this.baseUrl}/marketing-service/v2/app/marketing/seller/top-up`,
      {
        headers: this.privateHeaders,
        method: 'POST',
        body: JSON.stringify({
          Amount: amount,
        }),
      },
    )
  }

  public setRegisterLeadQuantity(body: { id: string; quantity: number }) {
    return fetcher<BaseResponse<number>>(
      `${this.baseUrl}/marketing-service/v2/app/marketing/lead-categories/${body.id}/registry`,
      {
        headers: this.privateHeaders,
        method: 'POST',
        body: JSON.stringify({ quantity: body.quantity }),
      },
    )
  }

  public getSellerSummary(params: FetchSellerSummaryRequestType) {
    return fetcher<BaseResponse<GetSellerBalanceResponse>>(
      `${
        this.baseUrl
      }/marketing-service/v2/app/marketing/seller/balance-summary?${qs.stringify(
        params,
      )}`,
      {
        headers: this.privateHeaders,
        method: 'GET',
      },
    )
  }
}

const marketingService = new MarketingService()

export { marketingService }
