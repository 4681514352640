import { WithChildren } from 'types/common'
import { flagList } from './flag-list'

type TFeatureFlagKey = keyof typeof flagList

export function checkFlagIsEnabled(key: TFeatureFlagKey) {
  return flagList[key]
}

interface FeatureFlagProps extends WithChildren {
  flagKey: TFeatureFlagKey
  fallback?: React.ReactNode
}

export const FeatureFlag = (props: FeatureFlagProps) => {
  const { children, flagKey, fallback } = props
  const isEnabled = checkFlagIsEnabled(flagKey)
  if (isEnabled) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{fallback}</>
}
