import React, { HTMLAttributes } from 'react'
import cx from 'classnames'
import noop from 'lodash/noop'
import { IconClose } from 'components/icons/components/IconClose'
import { IconButton } from 'components/ui/IconButton'
import { ToastAlertProvider } from './context'
import type { ToastScheme, ToastVariant } from '../Toast.types'

export interface ToastAlertProps extends HTMLAttributes<HTMLDivElement> {
  isClosable?: boolean
  fullWidth?: boolean
  scheme?: ToastScheme
  variant?: ToastVariant
  renderAction?: ({ onClose }: { onClose: () => void }) => React.ReactNode
  className?: string
  onClose?: () => void
}

export const ToastAlert: React.FC<ToastAlertProps> = ({
  isClosable = true,
  scheme = 'success',
  variant = 'solid',
  renderAction,
  fullWidth,
  className,
  children,
  onClose = noop,
  ...rest
}) => {
  const isFullWidth = fullWidth == null ? true : fullWidth
  const hasAction = typeof renderAction === 'function'

  return (
    <ToastAlertProvider variant={variant} scheme={scheme}>
      <div
        className={cx(
          'p-4 flex items-center text-left relative bg-gray-650 rounded-lg shadow-toast min-w-[180px]',
          {
            'pr-12': !hasAction,
            'pr-32': hasAction,
            'inline-flex w-max': !isFullWidth,
          },
          getBg(scheme, variant),
          getBorderColor(scheme, variant),
          className,
        )}
        {...rest}
      >
        {children}
        <div className="absolute flex items-center right-0 center-y space-x-4 pr-4">
          {hasAction && renderAction({ onClose })}

          {onClose && isClosable && (
            <IconButton
              size="xs"
              type="button"
              onClick={onClose}
              Icon={IconClose}
              label="close"
              className="h-full w-[20px]"
              iconClassName={cx(getCloseBtnColor(scheme, variant))}
            />
          )}
        </div>
      </div>
    </ToastAlertProvider>
  )
}

export const baseColorMap: Record<ToastScheme, string> = {
  primary: 'v2-primary-400',
  success: 'v2-success-400',
  error: 'v2-danger-400',
  warning: 'v2-warning-400',
  info: 'v2-secondary-400',
  gray: 'v2-',
}

export function getColorByScheme({
  scheme,
  variant = 'normal',
  prop,
  opacity,
}: {
  scheme: ToastScheme
  variant: 'dark' | 'normal' | 'light'
  prop: 'text' | 'bg' | 'border'
  opacity?: string
}) {
  const transformColor = (color: string) => {
    switch (variant) {
      case 'dark': {
        return getDarkColor(color)
      }
      case 'light': {
        return getLightColor(color)
      }
      case 'normal': {
        return color
      }
      default: {
        return color
      }
    }
  }

  return cx({
    [`${prop}-${transformColor(baseColorMap.success)} ${opacity}`]:
      scheme === 'success',
    [`${prop}-${transformColor(baseColorMap.error)} ${opacity}`]:
      scheme === 'error',
    [`${prop}-${transformColor(baseColorMap.warning)} ${opacity}`]:
      scheme === 'warning',
    [`${prop}-${transformColor(baseColorMap.info)} ${opacity}`]:
      scheme === 'info',
    [`${prop}-${transformColor(baseColorMap.primary)} ${opacity}`]:
      scheme === 'primary',
  })
}

function getBg(scheme: ToastScheme, variant: ToastVariant) {
  if (scheme === 'gray') return 'bg-[#3D3F40] bg-opacity-[.96]'

  if (variant === 'outlined') return 'bg-white'

  if (variant === 'solid') {
    return getColorByScheme({ scheme, variant: 'normal', prop: 'bg' })
  }

  return getColorByScheme({
    scheme,
    variant: 'normal',
    prop: 'bg',
    opacity: 'bg-opacity-[.5]',
  })
}

function getBorderColor(scheme: ToastScheme, variant: ToastVariant) {
  if (variant === 'outlined') {
    return cx(
      'border',
      getColorByScheme({ scheme, variant: 'normal', prop: 'border' }),
    )
  }

  return ''
}

function getCloseBtnColor(scheme: ToastScheme, variant: ToastVariant) {
  if (variant === 'outlined') return 'text-v2-gray-900'
  if (variant === 'solid') return 'text-white'

  return getColorByScheme({ scheme, variant: 'normal', prop: 'text' })
}

function getDarkColor(color: string) {
  return [color.replace('400', ''), '600'].join('')
}

function getLightColor(color: string) {
  return [color.replace('400', ''), '25'].join('')
}
